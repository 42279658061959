
* {
  padding: 0;
  margin: 0;
}

html {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: visible;
}

body {
  font-family: 'Nunito', sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
  overflow-x: hidden;
  background-color: #ffffff;
  color: #666666;
}

html, body, .wrapper {
  height: 100%;
}

h1, h2, h3, h4, h5, h6 {
  color: #232323;
  font-weight: normal;
  line-height: 1.2;
  font-family: 'Nunito', sans-serif;
  letter-spacing: 0;
  margin: 0;
}

h1,h2,h3,h4,h5,h6 {
  margin-bottom: 15px;
}

img {
  border: none;
  outline: none;
  max-width: 100%;
}

label {
  display: inline-block;
  font-weight: normal;
  margin-bottom: 5px;
  max-width: 100%;
}

a, .btn, button {
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  outline: medium none;
}

a img, iframe {
  border: none;
}

p {
  color: #666666;
  margin: 0 0 15px;
  text-transform: none;
  font-weight: 400;
}

hr {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-top: 1px solid #eee;
}

pre {
  display: block;
  margin: 0 0 30px;
  padding: 9.5px;
  word-wrap: break-word;
  word-break: break-all;
  color: #333;
  border: 1px solid #ededed;
  border-radius: inherit;
  background-color: #f9f9f9;
  font-size: 13px;
  line-height: 1.42857143;
}

input:focus, textarea:focus, 
 select:focus {
  outline: none;
  box-shadow: inherit;
}

ul {
  margin: 0;
  list-style-type: none;
}

a, a:active, a:focus, a:hover {
  outline: none;
  text-decoration: none;
  color: #232323;
}

a:hover {
  color: #232323;
}

a {
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  text-decoration: none;
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
}

b, strong {
  font-weight: 900;
}

.btn.active, .btn:active {
  background-image: inherit !important;
}

.btn.focus, 
 .btn:active:focus, 
 .btn:active:hover, 
 .btn:focus, 
 .btn:hover {
  outline: 0;
}

.btn.circle {
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
}

.btn.active, .btn:active {
  outline: 0;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
}

input {
  border: 1px solid #e7e7e7;
  border-radius: inherit;
  box-shadow: inherit;
  min-height: 50px;
}
/* Default CSS */
.container-medium {
  padding: 0 15px;
  margin: auto;
  max-width: 1400px;
}

.container-full {
  padding: 0 15px;
  margin: auto;
  max-width: 1400px;
}

@media (min-width:576px) {
  .container-medium {
    max-width: 540px;
  }
}

@media (min-width:768px) {
  .container-medium {
    max-width: 720px;
  }
}

@media (min-width:992px) {
  .container-medium {
    max-width: 960px;
  }
}

@media (min-width:1200px) {
  .container-medium {
    max-width: 1400px;
    width: 80%;
  }
}

@media (min-width:576px) {
  .container-full {
    max-width: 540px;
  }
}

@media (min-width:768px) {
  .container-full {
    max-width: 720px;
  }
}

@media (min-width:992px) {
  .container-full {
    max-width: 960px;
  }
}

@media (min-width:1200px) {
  .container-full {
    max-width: 1400px;
    width: 90%;
  }
}

.fixed-bg {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.bg-cover {
  background-position: center center;
  background-size: cover;
}

.bg-cover-bottom {
    background-size: cover !important;
    background-position: left bottom !important;
    background-repeat: no-repeat;
}

.bg-bottom-center {
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
}

.bg-contain {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.bg-fixed {
  background-attachment: fixed;
  background-position: center top;
  background-size: cover;
}

.bg-gray {
  background-color: #f3f7fd;
}

.bg-gray-hard {
  background-color: #d1eeec;
}

.bg-theme-small {
  background-color: #edf5ff;
}

.bg-light {
  background-color: #ffffff;
}

.bg-theme {
  background-color: #4154f1;
}

.bg-light-gradient {
  background: linear-gradient(90deg, rgba(244,247,252,1) 36%, rgba(255,255,255,1) 100%);
}

.gradient-bg {
  background-image: linear-gradient(90deg, rgba(2,59,166,1) 0%, rgba(67,126,235,1) 100%);
  background-color: #4154f1;
}

.container-md {
  width: 90%;
  margin: auto;
  position: relative;
}

.text-italic {
  font-style: italic;
}

.text-light h1, 
 .text-light h2, 
 .text-light h3, 
 .text-light h4, 
 .text-light h5, 
 .text-light h6, 
 .text-light p, 
 .text-light a {
  color: #ffffff;
}

.shadow {
  position: relative;
  z-index: 1;
}

.shadow.dark::after {
  background: #000000 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.shadow.dark-hard::after {
  background: #000000 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.75;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.shadow.light::after {
  background: #ffffff none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.shadow.theme::after {
  background: #4154f1 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.shadow.theme-hard::after {
  background: #4154f1 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.7;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.shadow.mixed::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0) linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 33, 71, 0.8) 100%) repeat scroll 0 0;
  z-index: -1;
  opacity: .7;
}

.default-padding,
.default-padding-top,
.default-padding-bottom,
.default-padding-mx {
  position: relative;
  z-index: 1;
}

.default-padding {
  padding-top: 120px;
  padding-bottom: 120px;
}

.default-padding-mx {
  padding-top: 150px;
  padding-bottom: 150px;
}

.default-padding-top {
  padding-top: 120px;
}

.default-padding-bottom {
  padding-bottom: 120px;
}

.default-padding.bottom-less {
  padding-top: 120px;
  padding-bottom: 90px;
}

.default-padding-bottom.bottom-less {
  margin-bottom: -30px;
}

.default-padding-top.bottom-less {
  margin-bottom: -30px;
}

.default-padding.bottom-30 {
  padding-top: 120px;
  padding-bottom: 30px;
}

.default-padding.bottom-20 {
  padding-top: 120px;
  padding-bottom: 20px;
}

.padding-xl {
  padding-top: 180px;
  padding-bottom: 180px;
}

.top-border {
  border-top: 1px solid #e7e7e7;
}

.align-center {
  align-items: center;
}

.btn {
  display: inline-block;
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  line-height: 25px;
  text-transform: capitalize;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  overflow: hidden;
  border-radius: 5px;
  font-size: 17px;
}

.btn.btn-icon i {
  font-size: 50px;
  align-items: center;
  float: left;
  margin-right: 10px;
}

.btn.btn-icon {
  padding: 0;
  line-height: 50px;
}

.btn.btn-icon:hover {
  color: #ffffff;
}

.btn-md {
  padding: 15px 40px;
}

.btn-sm {
  padding: 10px 35px;
  font-size: 14px;
}

.btn.btn-sm-pro {
  font-size: 10px;
  font-weight: 600;
  margin-top: 5px;
  padding: 4px 35px;
  display: inline-block;
}

.btn-border-light {
  border: 2px solid #ffffff;
}

.btn.btn-sm-pro.btn-border-light:hover, 
 .btn.btn-sm-pro.btn-border-light:focus {
  background-color: #ffffff;
  color: #232323;
  border: 2px solid #ffffff;
}

.btn-dark {
  background-color: #232323;
  color: #ffffff;
  border: 2px solid #232323;
}

.btn-dark.border {
  background-color: transparent;
  color: #232323;
  border: 2px solid #232323 !important;
}

.btn-dark.border:hover {
  background-color: #232323;
  color: #ffffff !important;
  border: 2px solid #232323 !important;
}

.btn-gray.border {
  background-color: transparent;
  color: #4154f1;
  border: 2px solid #e7e7e7 !important;
}

.btn-gray.border:hover {
  background-color: #4154f1;
  color: #ffffff !important;
  border: 2px solid #4154f1 !important;
}

.btn-gray.effect {
  background: #fafafa;
  border: 1px solid #e7e7e7 !important;
}

.btn.btn-light {
  background: #ffffff none repeat scroll 0 0;
  border: 2px solid #ffffff;
  color: #232323;
}

.btn.btn-light.border {
  background: transparent;
  border: 2px solid #ffffff !important;
  color: #ffffff;
}

.btn.btn-light.border:hover {
  background: #ffffff none repeat scroll 0 0 !important;
  border: 2px solid #ffffff !important;
  color: #232323 !important;
}

.btn-standard {
  display: inline-block;
  text-align: center;
  color: #4154f1;
  position: relative;
  margin-top: 15px;
  border: 1px solid #e7e7e7;
  padding: 10px 30px;
  text-transform: capitalize;
  border-radius: 30px;
  font-weight: 800;
}

.btn-standard.md {
  padding: 13px 45px;
}

.btn-standard i {
  display: inline-block;
  height: 35px;
  width: 35px;
  line-height: 35px;
  text-align: center;
  color: #4154f1;
  font-size: 15px;
  border-radius: 50%;
  position: relative;
  box-shadow: 0 0 10px #96aac1;
  z-index: 1;
  margin-right: 5px;
  background: #ffffff;
}

.bg-theme .btn-standard:hover,
.bg-dark .btn-standard:hover,
.bg-gradient .btn-standard:hover,
.shadow .btn-standard:hover {
  background: #ffffff;
  color: #4154f1;
}

.btn-dark:hover, 
 .btn-dark:focus {
  color: #232323 !important;
  background: transparent;
}

.bg-dark {
  background: #001d4c !important;
}

.bg-dark-hard {
  background: #08111e;
}

.bg-gradient {
  background: #4154F1;
  background: -webkit-linear-gradient(top left, #4154F1, #061169);
  background: -moz-linear-gradient(top left, #4154F1, #061169);
  background: linear-gradient(to bottom right, #4154F1, #061169);
}

.bg-gradient-bittersweet {
  background: #FF7268;
  background: -webkit-linear-gradient(top left, #FF7268, #B3332A);
  background: -moz-linear-gradient(top left, #FF7268, #B3332A);
  background: linear-gradient(to bottom right, #FF7268, #B3332A);
}

.orange .bg-gradient {
  background: #FF5621;
  background: -webkit-linear-gradient(90deg, rgba(255,86,33,1) 0%, rgba(240,57,0,1) 77%);
  background: -moz-linear-gradient(90deg, rgba(255,86,33,1) 0%, rgba(240,57,0,1) 77%);
  background: linear-gradient(90deg, rgba(255,86,33,1) 0%, rgba(240,57,0,1) 77%);
}

.cornflower-blue .bg-gradient {
  background: #644DED;
  background: -webkit-linear-gradient(top left, #644DED, #811389);
  background: -moz-linear-gradient(top left, #644DED, #811389);
  background: linear-gradient(to bottom right, #644DED, #811389);
}

.bg-gradient-light {
  background-image: linear-gradient(90deg, rgba(244,247,252,1) 0%, rgba(255,255,255,1) 100%);
}

.bg-shape-gradient {
  background-image: radial-gradient(circle at 50% 30%, rgba(255, 255, 255, 0.03) 0%, rgba(255, 255, 255, 0.03) 50%,rgba(138, 138, 138,0.04) 50%, rgba(138, 138, 138,0.04) 100%),radial-gradient(circle at 9% 40%, rgba(255, 255, 255, 0.03) 0%, rgba(255, 255, 255, 0.03) 50%,rgba(71, 71, 71,0.04) 50%, rgba(71, 71, 71,0.04) 100%),radial-gradient(circle at 84% 81%, rgba(147, 147, 147,0.04) 0%, rgba(147, 147, 147,0.04) 50%,rgba(253, 253, 253,0.04) 50%, rgba(253, 253, 253,0.04) 100%),linear-gradient(266deg, rgb(5, 73, 170),rgb(140, 100, 243));
}

.btn-theme {
  background-color: #4154f1;
  color: #ffffff !important;
  border: 2px solid #4154f1;
}

.btn-theme.orange {
  background-color: #FF5621;
  color: #ffffff !important;
  border: 2px solid #FF5621;
}

.btn-theme.border {
  background-color: transparent;
  color: #4154f1 !important;
  border: 2px solid #4154f1;
}

.btn-theme.border.orange {
  background-color: transparent;
  color: #FF5621 !important;
  border: 2px solid #FF5621;
}

.eastern-blue .btn-theme.border {
  background-color: transparent;
  color: #1baaa0 !important;
  border: 2px solid #1baaa0;
}

.orange .btn-theme.border {
  background-color: transparent;
  color: #FF5621 !important;
  border: 2px solid #FF5621;
}

.cornflower-blue .btn-theme.border {
  background-color: transparent;
  color: #634ded !important;
  border: 2px solid #634ded;
}

.btn-theme.border:hover {
  background-color: #4154f1;
  color: #ffffff !important;
  border: 2px solid #4154f1;
}

.btn-theme.border.orange:hover {
  background-color: #FF5621;
  color: #ffffff !important;
  border: 2px solid #FF5621;
}

.eastern-blue .btn-theme.border:hover {
  background-color: #1baaa0;
  color: #ffffff !important;
  border: 2px solid #1baaa0;
}

.orange .btn-theme.border:hover {
  background-color: #FF5621;
  color: #ffffff !important;
  border: 2px solid #FF5621;
}

.cornflower-blue .btn-theme.border:hover {
  background-color: #634ded;
  color: #ffffff !important;
  border: 2px solid #634ded;
}

.btn-theme.border:hover {
  background-color: #4154f1;
  color: #ffffff !important;
  border: 2px solid #4154f1;
}

.btn-theme.effect:hover, 
 .btn-theme.effect:focus {
  background: #4154f1 none repeat scroll 0 0;
  border: 2px solid #4154f1;
  color: #ffffff;
}

.btn-theme.orange.effect:hover, 
 .btn-theme.orange.effect:focus {
  background: #ffffff none repeat scroll 0 0;
  border: 2px solid #FF5621;
  color: #FF5621 !important;
}

.btn.btn-sm-lm {
  font-size: 12px;
  padding: 4px 35px;
}

.bg-theme a.btn-theme.btn:hover,
.bg-theme a.btn-theme.btn:focus {
  border: 2px solid #ffffff;
  color: #ffffff;
}

/* Gradient Button */

.btn-gradient,
.btn-gradient:focus {
  position: relative;
  z-index: 1;
  color: #ffffff !important;
}

.btn-gradient:hover {
  color: #ffffff;
}

.btn-gradient::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, rgba(65,84,241,1) 0%, rgba(14,32,173,1) 75%);
  transition: all 0.35s ease-in-out;
  z-index: -1;
  box-shadow: 0 0 10px rgba(255, 136, 0, 0.5), inset 0 0 1px 1px #4154f1;
}

.btn-gradient.eastern-blue::after {
  background: linear-gradient(90deg, rgba(27,170,160,1) 0%, rgba(8,115,107,1) 77%);
  box-shadow: 0 0 10px rgba(255, 136, 0, 0.5), inset 0 0 1px 1px rgb(27,170,160);
}

.btn-gradient.orange::after {
  background: linear-gradient(90deg, rgba(255,86,33,1) 0%, rgba(240,57,0,1) 77%);
  box-shadow: 0 0 10px rgba(255, 136, 0, 0.5), inset 0 0 1px 1px rgb(255,86,33);
}

.btn i {
  font-size: 12px;
}

.btn-gradient i {
  display: inline-block;
  line-height: 28px;
  color: #ffffff;
  font-size: 14px;
  margin-left: 5px;
}

.btn-gradient.icon-normal i {
  border: none;
  background: transparent;
  font-size: 20px;
  line-height: inherit;
  position: relative;
  top: 2px;
  margin-right: 0;
  height: auto;
  width: auto;
}

.btn-gradient.icon-left i {
  margin-left: 0;
  margin-right: 5px;
}

.inc-icon i {
  display: inline-block;
  height: 30px;
  width: 30px;
  line-height: 28px;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  font-size: 10px;
  border-radius: 50%;
  margin-left: 5px;
  border: 1px dashed rgba(255, 255, 255, 0.5);
}

.btn-gradient.btn-sm {
  padding: 10px 30px;
}

.btn.btn-transparent.border {
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
}

.btn.btn-transparent.border:hover {
  border: 1px solid rgba(255, 255, 255, 0.5) !important;
  background: rgba(255, 255, 255, 0.07);
  color: #ffffff !important;
}

.relative {
  position: relative;
}

.btn-simple.md {
  padding: 15px 60px;
}

.btn-simple.light {
  background: #ffffff;
}

.btn-simple {
  display: inline-block;
  border: 1px solid #e7e7e7;
  padding: 10px 40px;
  border-radius: 30px;
  color: #4154f1;
  font-weight: 700;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

a.btn-simple.md.light {
  color: #4154f1;
}

a.btn-simple.md.light:hover {
  color: #ffffff;
}

.btn-simple:after {
  background: linear-gradient(90deg, rgba(65,84,241,1) 0%, rgba(14,32,173,1) 75%);
  color: #ffffff;
  border-color: transparent;
  position: absolute;
  left: -100%;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  z-index: -1;
  transition: all 0.25s ease-in-out;
}

.btn-simple:hover {
  color: #ffffff;
}

.btn-simple:hover::after {
  left: 0;
}

.bg-fixed {
  background-attachment: fixed !important;
  background-position: center center !important;
  background-size: cover !important;
}

.bg-cover {
  background-position: center center !important;
  background-size: cover !important;
}

.overflow-hidden {
  overflow: hidden;
}

.less-margin {
  margin: 0;
}

button, button:focus {
  border: none !important;
  box-shadow: inherit !important;
  outline: inherit !important;
}

header {
  position: relative;
}

header nav.navbar.border.bootsnav.navbar-fixed.no-background {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

header nav.navbar.border.bootsnav.navbar-fixed.no-background ul li a {
  position: relative;
  z-index: 1;
  margin-left: 3px;
}

header nav.navbar.border.bootsnav.navbar-fixed.no-background .attr-nav ul li a {
  border: medium none;
}

header nav.navbar.border.bootsnav.navbar-fixed.no-background ul li a::after {
  background: #ffffff none repeat scroll 0 0;
  bottom: -2px;
  content: "";
  height: 3px;
  left: 0;
  position: absolute;
  width: 0;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

header nav.navbar.border.bootsnav.navbar-fixed.no-background ul li a.active::after,
header nav.navbar.border.bootsnav.navbar-fixed.no-background ul li a:hover::after {
  width: 100%;
}

header nav.navbar.border.bootsnav.navbar-fixed.no-background .attr-nav ul li a.active::after,
header nav.navbar.border.bootsnav.navbar-fixed.no-background .attr-nav ul li a:hover::after {
  width: 0;
}

.attr-nav .social.right li {
  margin-left: 20px;
}

.attr-nav .social li a {
  font-size: 16px;
}

/* Navbar */
nav.bootsnav.navbar-default.info-topbar .navbar-header {
  display: none;
}

nav.bootsnav.navbar-default.info-topbar ul li a {
  margin-right: 30px;
  padding: 35px 0;
}

nav.bootsnav.navbar-default.info-topbar.active-full ul li a {
  margin-right: 0;
  padding: 35px 20px;
}

.attr-nav > a.btn-theme.effect:hover, 
.attr-nav > a.btn-theme.effect, 
.attr-nav > a.btn-theme.effect:focus {
  background: #4154f1 none repeat scroll 0 0;
  border: 2px solid #4154f1;
  color: #ffffff !important;
}

nav.bootsnav.navbar-default.info-topbar.sticked ul li a {
  margin-right: 30px;
  padding: 35px 0;
}

.attr-nav.social li {
  display: inline-block;
  padding: 25px 0 !important;
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
}

.attr-nav.social li a {
  border: 1px solid #f4f4f4;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #4154f1;
  display: inline-block;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  margin-left: 10px;
  padding: 0 !important;
  text-align: center;
  width: 40px;
}

nav.navbar.bootsnav.sticked .attr-nav.social li {
  display: inline-block;
  padding: 20px 0 !important;
}

.site-heading h5 {
  text-transform: uppercase;
  font-weight: 800;
  color: #4154f1;
  font-size: 18px;
  position: relative;
  z-index: 1;
  display: inline-block;
}

.eastern-blue .site-heading h5 {
  color: #1baaa0;
}

.orange .site-heading h5 {
  color: #FF5621;
}

.cornflower-blue .site-heading h5 {
  color: #634ded;
}

.site-heading p {
  margin: 0;
}

.site-heading h2 {
  display: block;
  font-weight: 700;
  position: relative;
  margin-bottom: 0;
  line-height: 1.4;
  padding-bottom: 10px;
}

.heading-divider {
  display: inline-block;
  position: relative;
  height: 5px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background-color: #4154f1;
  width: 90px;
  overflow: hidden;
}

.eastern-blue .heading-divider {
  background-color: #1baaa0;
}

.orange .heading-divider {
  background-color: #FF5621;
}

.cornflower-blue .heading-divider {
  background-color: #634ded;
}

.heading-divider:after {
  content: '';
  position: absolute;
  left: 0;
  top: -1.1px;
  height: 7px;
  width: 8px;
  background-color: #ffffff;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: infinite-move;
  animation-name: infinite-move;
}

.bg-gray .heading-divider:after {
  background-color: #f3f7fd;
}

.site-heading {
  margin-bottom: 60px;
  overflow: hidden;
  margin-top: -5px;
}

.carousel-shadow .owl-stage-outer {
  margin: -15px -15px;
  padding: 15px;
}


/* ============================================================== 
     # Top Bar 
=================================================================== */
.language-switcher .dropdown-toggle {
  background: transparent;
  border: none;
  color: #666666;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 15px;
  text-transform: capitalize;
}

.language-switcher img {
  padding: 3px;
  box-shadow: 0 0 10px #cccccc;
  border-radius: 50%;
  height: 45px;
  width: 45px;
}

.language-switcher .dropdown-toggle li {
  display: block;
}

.language-switcher .dropdown-menu li {
  display: block;
  padding: 0;
  border-bottom: 1px solid #e7e7e7;
}

.language-switcher .dropdown-menu li:last-child {
  border: none;
}

.language-switcher .dropdown-menu {
  margin: 0;
  min-width: 200px;
  border-radius: inherit;
  border: 1px solid #e7e7e7;
  left: auto;
  right: 0;
}

.language-switcher .dropdown-menu.show {
  display: block !important;
}

.language-switcher .dropdown-menu li a {
  padding: 7px 15px;
  text-transform: capitalize;
  display: block;
  font-weight: 600;
}

.top-bar-area li {
  display: inline-block;
  margin-left: 20px;
}

.top-bar-area li i {
  margin-right: 5px;
}

.top-bar-area li:first-child {
  margin-left: 0;
}

.top-bar-area a {
  display: inline-block;
  background: #4154f1;
  padding: 10px 35px;
}

.orange .top-bar-area a {
  background: #FF5621;
}

/* ============================================================== 
     # Bradcrumb 
=================================================================== */
.breadcrumb-area {
  padding: 150px 0;
  position: relative;
}

.breadcrumb-area .fixed-bg {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
}

.breadcrumb-area .col-lg-8.offset-lg-2 {
  z-index: 9;
}

.breadcrumb-area h1 {
  font-weight: 700;
  color: #ffffff;
  margin: 0;
  text-transform: capitalize;
}

.breadcrumb-area .breadcrumb {
  background: #ffffff;
  display: inline-block;
  margin: 0;
  padding: 15px 35px;
  position: absolute;
  z-index: 9;
  left: 50%;
  bottom: -180px;
  transform: translate(-50%, 0);
  box-shadow: 0 5px 30px 0 rgba(214, 215, 216, 0.57);
  border-radius: 30px;
}

.page-title-area {
  padding: 150px 0;
}

.page-title-area h1 {
  display: block;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 40px;
  margin-top: -10px;
  margin-bottom: 0;
}

.breadcrumb-area .breadcrumb li::after {
  content: "";
  font-family: 'themify';
  font-weight: 600;
  color: #ffffff;
  position: absolute;
  right: -13px;
  height: 8px;
  width: 8px;
  border: 2px solid #c0b6b6;
  border-radius: 50%;
  top: 10px;
}

.breadcrumb-area .breadcrumb li i {
  margin-right: 5px;
}

.breadcrumb-area .breadcrumb li {
  padding: 0;
  position: relative;
  z-index: 1;
  margin: 0 7px;
  padding: 0 7px;
}

.breadcrumb-area .breadcrumb li:last-child::after {
  display: none;
}

.breadcrumb-area .breadcrumb a, 
.breadcrumb-area .breadcrumb li {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 14px;
  display: inline-block;
}

.breadcrumb-area .breadcrumb li.active {
  color: #4154f1;
}


/* SVG */

.svg-shape svg {
  margin-bottom: -10px;
}

.svg-shape svg.gray {
  fill: #f3f7fd;
}

.svg-shape svg.light {
  fill: #ffffff;
}

.svg-shape svg.theme {
  fill: #ff4450;
}

.svg-shape svg.dark {
  fill: #111d30;
}

.svg-shape svg.absolute {
  position: absolute;
}

.svg-shape svg.absolute.top {
  top: 0;
  left: 0;
}

.svg-shape svg.absolute.bottom {
  bottom: 0;
  left: 0;
}


/* ============================================================== 
     # Banner 
=================================================================== */
body, 
.banner-area, 
.banner-area div {
  height: 100%;
}

.banner-area.auto-height, 
.banner-area.auto-height div {
  height: auto;
}

.banner-area.auto-height .content {
  padding: 150px 0;
}

.banner-area .box-cell {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}

.banner-area .box-table {
  display: table;
  width: 100%;
}

.banner-area .box-cell, 
 .banner-area .box-cell div {
  height: auto;
}

.banner-area {
  position: relative;
  overflow: hidden;
}

.banner-area .wavesshape {
  bottom: -2px;
  left: 0;
  position: absolute;
  right: 0;
  top: auto;
  width: 100%;
}

.banner-area .wavesshape.shape {
  bottom: -50px;
  height: auto;
}

.banner-area .content {
  position: relative;
  z-index: 9;
}

.banner-area.wavesshape {
  position: relative;
  z-index: 1;
}

.banner-area.triangle-shadow {
  position: relative;
  z-index: 1;
}

.banner-area.triangle-shadow::before {
  border-bottom: 0 solid transparent;
  border-right: 100vw solid #fff;
  border-top: 80px solid transparent;
  bottom: 0;
  content: "";
  height: 0;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 101;
}

.banner-area.shadow-inner .content {
  padding: 30px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.banner-area.content-double .double-items {
  align-items: center;
  align-self: center;
  display: flex;
  justify-content: center;
  vertical-align: middle;
}

.banner-area .banner-carousel .owl-item.center img {
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}

.banner-area .banner-carousel .owl-item img {
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  -o-transform: scale(0.9);
}

/* Animation delays */
.banner-area .item h1:first-child, 
 .banner-area .item h2:first-child, 
 .banner-area .item h3:first-child, 
 .banner-area .item h4:first-child, 
 .banner-area .item h5:first-child, 
 .banner-area .item h6:first-child {
  animation-delay: .5s;
}

.banner-area .item h1:nth-child(2), 
 .banner-area .item h2:nth-child(2), 
 .banner-area .item h3:nth-child(2), 
 .banner-area .item h4:nth-child(2), 
 .banner-area .item h5:nth-child(2), 
 .banner-area .item h6:nth-child(2) {
  animation-delay: .7s;
}

.carousel-caption h1:nth-child(3), 
 .carousel-caption h2:nth-child(3), 
 .carousel-caption h3:nth-child(3), 
 .carousel-caption h4:nth-child(3), 
 .carousel-caption h5:nth-child(3), 
 .carousel-caption h6:nth-child(3) {
  animation-delay: .9s;
}

.banner-area .item p {
  animation-delay: .5s;
}

.banner-area .item ul {
  animation-delay: .6s;
}

.banner-area .item a, 
 .banner-area .item button {
  animation-delay: .7s;
}

/* Carousel Fade Effect */
.carousel-fade .carousel-inner .item {
  -webkit-transition-property: opacity;
  transition-property: opacity;
}

.carousel-fade .carousel-inner .item, 
 .carousel-fade .carousel-inner .active.left, 
 .carousel-fade .carousel-inner .active.right {
  opacity: 0;
}

.carousel-fade .carousel-inner .active, 
 .carousel-fade .carousel-inner .next.left, 
 .carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}

.carousel-fade .carousel-inner .next, 
 .carousel-fade .carousel-inner .prev, 
 .carousel-fade .carousel-inner .active.left, 
 .carousel-fade .carousel-inner .active.right {
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
}

.carousel-fade .carousel-control {
  z-index: 2;
}
/* Slider Zoom Effect */
@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(1, 1);
  }

  to {
    -webkit-transform: scale(1.2, 1.2);
  }
}

@-moz-keyframes zoom {
  from {
    -moz-transform: scale(1, 1);
  }

  to {
    -moz-transform: scale(1.2, 1.2);
  }
}

@-o-keyframes zoom {
  from {
    -o-transform: scale(1, 1);
  }

  to {
    -o-transform: scale(1.2, 1.2);
  }
}

@keyframes zoom {
  from {
    transform: scale(1, 1);
  }

  to {
    transform: scale(1.2, 1.2);
  }
}

.carousel-inner .item > .slider-thumb {
  -webkit-animation: zoom 20s;
  animation: zoom 20s;
}

.banner-area .carousel-zoom .slider-thumb {
  height: 100%;
  position: absolute;
  width: 100%;
}

#particles-js,
#ripple {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.banner-area .carousel-control {
  background: transparent none repeat scroll 0 0;
  height: 50px;
  font-size: 30px;
  line-height: 50px;
  margin-top: -25px;
  opacity: 1;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 70px;
  z-index: 1;
  text-transform: uppercase;
}

.banner-area .carousel-control.shadow {
  background: transparent none repeat scroll 0 0;
  color: #ffffff;
  font-size: 20px;
  height: 50px;
  line-height: 50px;
  margin-top: -25px;
  opacity: 1;
  padding: 0;
  position: absolute;
  text-shadow: inherit;
  top: 50%;
  width: 50px;
  z-index: 1;
}

.banner-area .carousel-control.shadow::after {
  background: #1e2726 none repeat scroll 0 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.banner-area .carousel-control.left {
  left: -80px;
}

.banner-area:hover .carousel-control.left {
  left: 10px;
}

.banner-area:hover .carousel-control.shadow.left {
  left: 20px;
}

.banner-area .carousel-control.right {
  right: -80px;
}

.banner-area:hover .carousel-control.right {
  right: 10px;
}

.banner-area:hover .carousel-control.shadow.right {
  right: 20px;
}


/* Carousel Indicators */

.banner-area .carousel-indicator {
  position: absolute;
  left: 10px;
  bottom: 0;
  width: 100%;
}

.banner-area .carousel-indicators {
  position: absolute;
  right: auto;
  left: 0;
  width: auto;
  height: auto;
  top: auto;
  bottom: 0;
  margin: 0;
  padding: 30px 0;
}

.banner-area .carousel-indicators li {
  display: block;
  height: 20px;
  width: 20px;
  margin: 10px 5px;
  border: 2px solid rgba(255, 255, 255, 0.7);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: transparent;
  position: relative;
  z-index: 1;
}

.banner-area .carousel-indicators li.active {
  border: 2px solid #ffffff;
}

.banner-area .carousel-indicators li::after {
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  height: 5px;
  width: 5px;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  background: #ffffff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.banner-area.video-bg-live .mbYTP_wrapper {
  z-index: 1 !important;
}

.double-items > .row div {
  height: auto;
}

/* Shape */
.shape {
  z-index: -1;
}

.shape-right-top {
  position: absolute;
  right: 0;
  top: 0;
}

.shape-right-bottom {
  position: absolute;
  right: 0;
  bottom: 0;
}

.shape-left-bottom {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100% !important;
  width: 100%;
}

.shape-left-top {
  position: absolute;
  left: 0;
  top: -120px;
}

.shape-right-top img,
.shape-right-bottom img,
.shape-left-bottom img {
  height: 100%;
}

.shape-left-bottom img {
  height: 100%;
  max-width: 100%;
  min-width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.shape-bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.shape-bottom img {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.shape-top {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.shape-top img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.shape.opacity-1 {
  opacity: 1;
}

.shape.opacity-09 {
  opacity: 0.9;
}

.shape.opacity-08 {
  opacity: 0.8;
}

.shape.opacity-07 {
  opacity: 0.7;
}

.shape.opacity-06 {
  opacity: 0.6;
}

.shape.opacity-05 {
  opacity: 0.5;
}

.shape.opacity-04 {
  opacity: 0.4;
}

.shape.opacity-03 {
  opacity: 0.3;
}

.shape.opacity-02 {
  opacity: 0.2;
}

.shape.opacity-01 {
  opacity: 0.1;
}

.shape.opacity-default {
  opacity: 0.03;
}


/* Content */

.banner-area h2 {
  font-size: 50px;
  font-weight: 900;
  margin-bottom: 20px;
}

.banner-area h2 strong {
  position: relative;
  z-index: 1;
}

.banner-area h2 span {
  color: #4154f1;
}

.orange .banner-area h2 span {
  color: #FF5621;
}

.eastern-blue .banner-area h2 span {
  color: #1baaa0;
}

.banner-area h4 {
  color: #4154f1;
  margin-bottom: 26px;
  text-transform: uppercase;
  font-weight: 700;
}

.banner-area.text-light h4 {
  color: #ffffff;
}

.eastern-blue .banner-area h4 {
  color: #1baaa0;
}

.banner-area.heading-capitalized h2 {
  font-weight: 200;
  text-transform: capitalize;
  font-size: 60px;
}

.banner-area.heading-capitalized h2 strong {
  font-weight: 700;
  display: block;
}

.banner-area.heading-capitalized h2 strong::after {
  display: none;
}

.banner-area h2 strong::after {
  position: absolute;
  left: 3%;
  bottom: 15px;
  content: "";
  height: 10px;
  width: 100%;
  background: #4154f1;
  z-index: -1;
  opacity: .2;
}

.banner-area a {
  margin-top: 15px;
}

.banner-area p {
  font-size: 18px;
  line-height: 34px;
}

.banner-area .thumb {
  padding-left: 35px;
}

.banner-area .thumb.width-120 img {
  max-width: 120%;
}

.banner-area .thumb.width-130 img {
  max-width: 130%;
}

.banner-area .thumb.width-140 img {
  max-width: 140%;
}

.banner-area .thumb-innner {
  position: relative;
  z-index: 1;
}

.banner-area .thumb-innner .icon {
  position: absolute;
  left: 50%;
  top: 50px;
  margin-left: -10px;
}

.banner-area.auto-height .double-items {
  padding: 100px 0;
}

.banner-area.auto-height .content-box {
  padding-top: 180px;
}

.banner-area.auto-height .content-box .thumb-innner {
  margin-top: 50px;
}

.banner-area.auto-height .content-box .thumb-innner .icon {
  position: absolute;
  left: 50%;
  top: 100px;
  margin-left: -55px;
}

.banner-area.circle-shape {
  position: relative;
  z-index: 1;
}

.banner-area.circle-shape::after {
  position: absolute;
  right: -150px;
  top: -150px;
  content: "";
  height: 600px;
  width: 600px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 50%;
  z-index: -1;
}

.banner-area.circle-shape::before {
  position: absolute;
  right: -150px;
  top: -150px;
  content: "";
  height: 700px;
  width: 700px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 50%;
  z-index: -1;
}


/* Animated Shape */

.item-animated {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  -webkit-animation-name: infiUpDown;
  animation-name: infiUpDown;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

@keyframes infiUpDown {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(-35px);
    transform: translateY(-35px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-webkit-keyframes infiUpDown {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(-35px);
    transform: translateY(-35px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-webkit-keyframes infinite-move {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
}

@-webkit-keyframes infinite-move {
  from {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -ms-transform: translateX(88px);
    transform: translateX(88px);
  }
}

@keyframes infinite-move {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
}


/* ============================================================== 
     # Video Button Play
=================================================================== */
.video-play-button {
  color: #4154f1;
  font-size: 30px;
  left: 50%;
  padding-left: 7px;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  z-index: 1;
}

.video-play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #ffffff repeat scroll 0 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-animation: pulse-border 1500ms ease-out infinite;
  animation: pulse-border 1500ms ease-out infinite;
}

.video-play-button.theme:before,
.video-play-button.theme:after {
  background: #4154f1 repeat scroll 0 0;
}

.orange .video-play-button.theme:before,
.orange .video-play-button.theme:after {
  background: #FF5621 repeat scroll 0 0;
}

.video-play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #ffffff repeat scroll 0 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  transition: all 200ms;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -ms-transition: all 200ms;
  -o-transition: all 200ms;
}

@-webkit-keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

@keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

.video-play-button i {
  display: block;
  position: relative;
  z-index: 3;
  color: #4154f1;
}

.video-play-button.theme i {
  color: #ffffff;
}

.video-play-button.relative {
  position: relative;
  display: inline-block;
  left: 30px;
  height: 68px;
  width: 68px;
  line-height: 68px;
  text-align: center;
  margin-top: 0 !important;
  top: 55px;
  margin-left: 10px;
}

.video-play-button.relative::before, 
.video-play-button.relative::after {
  height: 68px;
  width: 68px;
  line-height: 68px;
}

.video-play-button.relative i {
  line-height: 70px;
  font-size: 25px;
}

/* Nice Select CSS */
.nice-select {
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  border: solid 1px #e8e8e8;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  height: 50px;
  line-height: 50px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: 100%;
  margin-bottom: 15px;
  z-index: 1;
}

.nice-select:hover {
  border-color: #dbdbdb;
}

.nice-select:active, 
.nice-select.open, 
.nice-select:focus {
  border-color: #999;
}

.nice-select:after {
  border-bottom: 2px solid #999;
  border-right: 2px solid #999;
  content: '';
  display: block;
  height: 5px;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 50%;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 5px;
}

.nice-select.open:after {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
  overflow-y: auto !important;
  height: 200px;
}

.nice-select.disabled {
  border-color: #ededed;
  color: #999;
  pointer-events: none;
}

.nice-select.disabled:after {
  border-color: #96aac1;
}

.nice-select.wide {
  width: 100%;
}

.nice-select.wide .list {
  left: 0 !important;
  right: 0 !important;
}

.nice-select.right {
  float: right;
}

.nice-select.right .list {
  left: auto;
  right: 0;
}

.nice-select.small {
  font-size: 12px;
  height: 36px;
  line-height: 34px;
}

.nice-select.small:after {
  height: 4px;
  width: 4px;
}

.nice-select.small .option {
  line-height: 34px;
  min-height: 34px;
}

.nice-select .list {
  background-color: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  -webkit-box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  -o-box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: scale(0.75) translateY(-21px);
  -ms-transform: scale(0.75) translateY(-21px);
  transform: scale(0.75) translateY(-21px);
  -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
  width: 100%;
}

.nice-select .list:hover .option:not(:hover) {
  background-color: transparent !important;
}

.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  min-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.nice-select .option:hover, 
.nice-select .option.focus, 
.nice-select .option.selected.focus {
  background-color: #f6f6f6;
}

.nice-select .option.selected {
  font-weight: bold;
}

.nice-select .option.disabled {
  background-color: transparent;
  color: #999;
  cursor: default;
}

.no-csspointerevents .nice-select .list {
  display: none;
}

.no-csspointerevents .nice-select.open .list {
  display: block;
}


/* ============================================================== 
     # Features
=================================================================== */

.features-area .shape {
  opacity: 0.03;
}

.features-area .feature-box .single-item {
  margin-bottom: 30px;
}

.features-area .feature-box .item {
  box-shadow: 0 5px 30px 0 rgba(214, 215, 216, 0.57);
  padding: 60px 37px;
  background: #ffffff;
}

.features-area .about-info {
  margin-top: 90px;
}

.features-area .item .icon {
  display: inline-block;
  height: 80px;
  width: 80px;
  line-height: 80px;
  text-align: center;
  background: rgba(65, 84, 241, 1);
  border-radius: 63% 37% 30% 70% / 50% 45% 55% 50%;
  position: relative;
  z-index: 1;
  font-size: 30px;
  color: #ffffff;
  margin-bottom: 25px;
  box-shadow: 0px 10px 30px 0px rgba(44, 130, 237, 0.4);
}

.features-area .single-item:nth-child(2) .item .icon {
  background: rgba(27, 170, 160, 1);
}

.features-area .single-item:nth-child(3) .item .icon {
  background: rgba(255, 86, 33, 1);
}

.features-area .single-item:nth-child(4) .item .icon {
  background: rgba(129, 19, 137, 1);
}

.features-area .item h4 {
  font-weight: 800;
  font-size: 20px;
}

.features-area .item p {
  margin: 0;
}

/* About info */
.about-info h2 {
  font-weight: 200;
  margin-bottom: 25px;
  margin-top: -5px;
  text-transform: capitalize;
}

.about-info h2 strong {
  display: block;
  margin-top: 5px;
}

.about-info .fun-fact {
  display: flex;
  margin-top: 25px;
}

.about-info .fun-fact i {
  display: inline-block;
  margin-right: 20px;
  font-size: 30px;
  color: #4154f1;
}

.about-info .fun-fact .timer {
  color: #232323;
  font-size: 40px;
  margin-bottom: 10px;
  font-weight: 700;
  display: inline-block;
  position: relative;
  z-index: 1;
}

.about-info .fun-fact .medium {
  display: block;
  font-size: 18px;
}

.about-info .fun-fact .timer::after {
  position: absolute;
  right: -32px;
  top: 0;
  content: "%";
  font-size: 30px;
}

.about-info a {
  margin-top: 10px;
}

/* About Features */
.about-features-area .about-info {
  padding-right: 35px;
}

.about-features-area .item-grid:first-child {
  margin-top: -30px;
}

.about-area .row > .process-box {
    padding-left: 35px;
}

.about-features-area .process-box .item {
  padding: 40px 30px;
  background: #ffffff;
  box-shadow: 0 5px 30px 0 rgba(214, 215, 216, 0.57);
  margin-top: 30px;
}

.about-features-area .process-box .item:first-child .icon {
  position: relative;
}

.about-features-area .process-box .icon i {
    text-align: center;
    background: #ffffff;
    position: relative;
    z-index: 1;
    margin-bottom: 20px;
    font-size: 30px;
    color: #1baaa0;
    height: 80px;
    width: 80px;
    line-height: 80px;
}

.cornflower-blue .about-features-area .process-box .icon i {
  color: #634ded;
}

.about-features-area .process-box .icon i::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    height: 100%;
    width: 100%;
    background: #634ded;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    opacity: 0.1;
    border-radius: 63% 37% 30% 70% / 50% 45% 55% 50%;
}

.about-features-area .process-box .item h4 {
  text-transform: capitalize;
  font-weight: 800;
  font-size: 22px;
}


/* Analysis */

.analysis h4 {
  border-top: 1px dashed #e7e7e7;
  margin-top: 30px;
  padding-top: 30px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.4;
}

.analysis form button {
  width: 100%;
  background: linear-gradient(90deg, rgba(65,84,241,1) 0%, rgba(14,32,173,1) 75%);
  color: #ffffff;
  font-weight: 800;
  text-transform: uppercase;
  padding: 7px;
  border-radius: 30px;
  margin-top: 30px;
}

.analysis form input,
.analysis form input:focus {
  border-radius: 30px;
  border: none;
  padding: 15px 30px;
  outline: inherit;
  box-shadow: inherit;
}

.analysis .input {
  border: 1px solid #e7e7e7;
  border-radius: 30px;
  padding: 0 20px;
}

.analysis form .col-lg-6 {
  padding: 0;
  border-radius: 30px;
}

.analysis .input .form-group {
  margin: 0;
}

.analysis form .input input[type="url"] {
  border-right: 1px solid #e7e7e7;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}


/* ============================================================== 
     # About
=================================================================== */

.about-area .thumb {
  padding-right: 35px;
}

.about-area .info h5 {
  text-transform: uppercase;
  font-weight: 800;
  color: #4154f1;
}

.eastern-blue .about-area .info h5 {
  color: #1baaa0;
}

.orange .about-area .info h5 {
  color: #FF5621;
}

.cornflower-blue .about-area .info h5 {
  color: #634ded;
}

.about-area .info h2 {
  font-weight: 200;
  margin-bottom: 25px;
  text-transform: capitalize;
}

.about-area .info h2 strong {
  margin-top: 5px;
}

/* ============================================================== 
     # Cirlce Progressbar
=================================================================== */
.circle-chart {
  width: 150px;
  height: 150px;
}

.circle-chart__circle {
  stroke: #1baaa0;
  stroke-width: 2px;
  stroke-linecap: square;
  fill: none;
  animation: circle-chart-fill 2s reverse;
  transform: rotate(-90deg);
  transform-origin: center;
}

.cornflower-blue .circle-chart__circle {
  stroke: #634ded;
}

/**
 * 1. Rotate by -90 degree to make the starting point of the
 *    stroke the top of the circle.
 * 2. Scaling mirrors the circle to make the stroke move right
 *    to mark a positive chart value.
 * 3. Using CSS transforms on SVG elements is not supported by Internet Explorer
 *    and Edge, use the transform attribute directly on the SVG element as a
 * .  workaround.
 */

.circle-chart__circle--negative {
  transform: rotate(-90deg) scale(1,-1);
 /* 1, 2, 3 */;
}

.circle-chart__background {
  stroke: #e7e7e7;
  stroke-width: 1px;
  fill: none;
}

.circle-chart__info {
  animation: circle-chart-appear 2s forwards;
  opacity: 0;
  transform: translateY(0.3em);
}

.circle-chart__percent {
  alignment-baseline: central;
  text-anchor: middle;
  font-size: 8px;
  font-weight: 800;
}

.circle-chart__subline {
  alignment-baseline: central;
  text-anchor: middle;
  font-size: 3px;
}

.progress-items .circlechart {
  float: left;
  margin-right: 30px;
  margin-top: 25px !important;
}

.progress-items .circlechart:last-child {
  margin: 0;
}

@keyframes circle-chart-fill {
  to {
    stroke-dasharray: 0 100;
  }
}

@keyframes circle-chart-appear {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.about-area .progress-items {
    margin-top: 25px;
}

.progress-items .progress-box {
    margin-bottom: 30px;
}

.progress-items .progress-box:last-child {
    margin-bottom: 0;
}

.progress-items .progress-box h5 {
    text-transform: capitalize;
    color: #232323 !important;
    font-size: 16px;
    font-weight: 600;
}

.progress-items .progress-box .progress {
    height: 5px;
    border-radius: 30px;
    overflow: inherit;
}

.progress-items .progress-box .progress .progress-bar {
    background: #4154f1;
    position: relative;
    z-index: 1;
    overflow: inherit;
}

.progress-items .progress-box .progress .progress-bar::after {
    position: absolute;
    right: 0;
    top: 0;
    content: "";
    height: 15px;
    width: 2px;
    background: #4154f1;
    z-index: -1;
    margin-top: -5px;
}

.eastern-blue .progress-items .progress-box .progress .progress-bar {
    background: #1baaa0;
}

.eastern-blue .progress-items .progress-box .progress .progress-bar::after {
    background: #1baaa0;
}

.orange .progress-items .progress-box .progress .progress-bar {
    background: #FF5621;
}

.orange .progress-items .progress-box .progress .progress-bar::after {
    background: #FF5621;
}

.cornflower-blue .progress-items .progress-box .progress .progress-bar {
    background: #634ded;
}

.cornflower-blue .progress-items .progress-box .progress .progress-bar::after {
    background: #634ded;
}

/* ============================================================== 
     # Choose Us
=================================================================== */

.choose-us-area.half-bg::after {
  position: absolute;
  right: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 50%;
  background: #001d4c;
  z-index: -1;
}

.choose-us-area .info {
  padding-left: 50px;
  position: relative;
  z-index: 1;
}

.choose-us-area .info::after {
  position: absolute;
  left: -10px;
  top: -50px;
  content: "";
  height: 150px;
  width: 150px;
  background: url(../img/shape/dotted-bg.png);
  z-index: -1;
  transform: rotate(-45deg);
  background-size: contain;
  background-repeat: no-repeat;
}

.choose-us-area ul li {
  float: left;
  width: 50%;
  padding: 0 15px;
  margin-top: 30px;
}

.choose-us-area ul li .item {
  background: #ffffff;
  border-radius: 30px;
  display: flex;
  align-items: center;
  box-shadow: 0 5px 30px 0 rgba(214, 215, 216, 0.57);
}

.choose-us-area ul {
  margin-top: -10px;
}

.choose-us-area ul li h5 {
  margin-bottom: 7px;
  font-size: 18px;
  font-weight: 700;
}

.choose-us-area ul li {
  position: relative;
  z-index: 1;
  padding-left: 50px;
  line-height: 30px;
}

.choose-us-area ul li p {
  margin-bottom: 0;
}

.choose-us-area ul li::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  background: #f7f7f7;
  color: #4154f1;
  height: 40px;
  width: 35px;
  line-height: 40px;
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  clip-path: polygon(100% 0, 100% 77%, 77% 100%, 0 100%, 0 0);
}

.choose-us-area ul li i {
  display: inline-block;
  margin-right: 15px;
  font-size: 30px;
  height: 60px;
  width: 60px;
  background: #4154f1;
  line-height: 60px;
  text-align: center;
  color: #ffffff;
  border-radius: 50%;
}

.choose-us-area h2 {
  margin-bottom: 30px;
  font-weight: 600;
}

.choose-us-area h2 strong {
  text-decoration: underline;
}

.choose-us-area .thumb {
  padding-right: 35px;
}

.choose-us-area a {
  margin-top: 15px;
}

/* ============================================================== 
     # Services
=================================================================== */

@media only screen and (min-width: 1367px) {
  .services-area .container-lg {
    margin-left: calc((100% - 1140px)/ 2);
    padding-right: 0;
    width: auto;
    min-width: auto;
    max-width: inherit;
  }
}

@media only screen and (min-width: 1000px) {
  .services-area .services-stage-carousel.owl-carousel .owl-stage {
    left: -100px;
  }
}

.services-area .single-item {
  margin-bottom: 30px;
}

.services-area .services-items .item {
  padding: 60px 37px;
  background: #ffffff;
  box-shadow: 0 5px 30px 0 rgba(214, 215, 216, 0.57);
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.services-area.bg-gray .services-items .item,
.services-area .services-items.services-stage-carousel .item {
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  border: none;
}

.services-area .services-items .item::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 100%;
  width: 40%;
  background: url(../img/shape/border-shape.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left bottom;
  z-index: -1;
  opacity: 0.5;
}

.services-area.bg-gray .services-items .item::after {
  opacity: 0.5;
}

.services-area .services-items .item .icon {
  margin-bottom: 30px;
  display: inline-block;
  position: relative;
  z-index: 1;
}

.services-area .services-items .item:hover .icon::after {
  height: 15px;
  width: 15px;
}

.services-area .services-items .single-item .item .icon::before,
.services-area .services-items .single-item .item .icon::after {
  display: none;
}

.services-area .services-items .item:hover .icon::before {
  height: 10px;
  width: 10px;
}

.services-area .services-items .item .icon i {
  display: inline-block;
  font-size: 45px;
  height: 80px;
  width: 80px;
  line-height: 80px;
  text-align: center;
  background: linear-gradient(to bottom, #4154F1, #011CFF);
  color: #ffffff;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  transition: all 0.35s ease-in-out;
}

.services-area .services-items .single-item .item .icon i {
  font-size: 35px;
}

.services-area .services-items .single-item .icon i,
.services-area .services-items .single-item .icon i::after {
  border-radius: 50% !important;
}

.eastern-blue .services-area .services-items .item .icon i {
  background: #1BAAA0;
  background: -webkit-linear-gradient(top left, #1BAAA0, #11EBDB);
  background: -moz-linear-gradient(top left, #1BAAA0, #11EBDB);
  background: linear-gradient(to bottom right, #1BAAA0, #11EBDB);
}

.services-area .services-items .item .icon i::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 100%;
  width: 100%;
  z-index: -1;
  border-radius: 10px;
  box-shadow: 0px 10px 30px 0px rgba(44, 130, 237, 0.4);
}

.services-area .services-items .item h4 {
  font-weight: 700;
  text-transform: capitalize;
  font-size: 20px;
}

.services-area .services-items .item .info > a {
  text-transform: capitalize;
  padding: 10px 35px;
  position: relative;
  z-index: 1;
  margin-top: 5px;
  display: inline-block;
  font-size: 16px;
  color: #4154f1;
  float: right;
}

.eastern-blue .services-area .services-items .item .info > a {
  color: #1baaa0;
}

.orange .services-area .services-items .item .info > a {
  color: #232323;
}

.cornflower-blue .services-area .services-items .item .info > a {
  color: #634ded;
}

.services-area .services-items.text-center .item .info > a {
  float: none;
}

.services-area .services-items .item .info > a i {
  margin-right: 3px;
  font-size: 12px;
}

.services-area .services-items .item .info > a::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 52px;
  background: linear-gradient(-70deg, transparent, rgba(237, 245, 255, 1));
  z-index: -1;
  border-radius: 50%;
  transition: all 0.35s ease-in-out;
}

.services-area .services-items .item .info > a:hover::after {
  border-radius: 30px;
  width: 100%;
  color: #ffffff;
}

.services-area .services-items .owl-item:nth-child(1) .item .icon i,
.services-area .services-items .single-item:nth-child(1) .item .icon i {
  background: #1BAAA0;
  background: -webkit-linear-gradient(top left, #1BAAA0, #11EBDB);
  background: -moz-linear-gradient(top left, #1BAAA0, #11EBDB);
  background: linear-gradient(to bottom right, #1BAAA0, #11EBDB);
}

.services-area .services-items .owl-item:nth-child(2n) .item .icon i,
.services-area .services-items .single-item:nth-child(2n) .item .icon i {
  background: #653CB5;
  background: -webkit-linear-gradient(top left, #653CB5, #BB99FF);
  background: -moz-linear-gradient(top left, #653CB5, #BB99FF);
  background: linear-gradient(to bottom right, #653CB5, #BB99FF);
}

.services-area .services-items .owl-item:nth-child(3n) .item .icon i,
.services-area .services-items .single-item:nth-child(3n) .item .icon i {
  background: #F84E77;
  background: -webkit-linear-gradient(top left, #F84E77, #FFA3B9);
  background: -moz-linear-gradient(top left, #F84E77, #FFA3B9);
  background: linear-gradient(to bottom right, #F84E77, #FFA3B9);
}

.services-area .services-items .owl-item:nth-child(4n) .item .icon i,
.services-area .services-items .single-item:nth-child(4n) .item .icon i {
  background: #4154F1;
  background: -webkit-linear-gradient(top left, #4154F1, #6C7CFF);
  background: -moz-linear-gradient(top left, #4154F1, #6C7CFF);
  background: linear-gradient(to bottom right, #4154F1, #6C7CFF);
}

.services-area .services-items .owl-item:nth-child(5n) .item .icon i,
.services-area .services-items .single-item:nth-child(5n) .item .icon i {
  background: #FC573C;
  background: -webkit-linear-gradient(top left, #FC573C, #FB9281);
  background: -moz-linear-gradient(top left, #FC573C, #FB9281);
  background: linear-gradient(to bottom right, #FC573C, #FB9281);
}

.services-area .services-items .owl-item:nth-child(6n) .item .icon i,
.services-area .services-items .single-item:nth-child(6n) .item .icon i {
  background: #63BC6F;
  background: -webkit-linear-gradient(top left, #63BC6F, #A0F8AC);
  background: -moz-linear-gradient(top left, #63BC6F, #A0F8AC);
  background: linear-gradient(to bottom right, #63BC6F, #A0F8AC);
}

.services-area .services-items.services-carousel .owl-nav {
  margin: 0;
}

.services-area .services-items.services-carousel .owl-nav .owl-prev, 
.services-area .services-items.services-carousel .owl-nav .owl-next {
  background: #ffffff none repeat scroll 0 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -moz-box-shadow: 0 0 1px 1px rgba(20, 23, 28, 0.1), 0 3px 1px 0 rgba(20, 23, 28, 0.1);
  -webkit-box-shadow: 0 0 1px 1px rgba(20, 23, 28, 0.1), 0 3px 1px 0 rgba(20, 23, 28, 0.1);
  -o-box-shadow: 0 0 1px 1px rgba(20, 23, 28, 0.1), 0 3px 1px 0 rgba(20, 23, 28, 0.1);
  box-shadow: 0 0 1px 1px rgba(20, 23, 28, 0.1), 0 3px 1px 0 rgba(20, 23, 28, 0.1);
  color: #4154f1;
  font-size: 20px;
  height: 45px;
  left: -90px;
  line-height: 50px;
  margin-top: -23px;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  width: 45px;
  opacity: 0;
}

.services-area .services-items.services-carousel .owl-nav .owl-next {
  left: auto;
  right: -90px;
}

.services-area .services-items.services-carousel:hover .owl-nav .owl-prev {
  left: -70px;
  opacity: 1;
}

.services-area .services-items.services-carousel:hover .owl-nav .owl-next {
  left: auto;
  right: -70px;
  opacity: 1;
}

.services-stage-carousel.owl-carousel .owl-dots {
}

.services-stage-carousel.owl-carousel .owl-dots .owl-dot {
  margin-bottom: -10px;
  margin-top: 20px;
}

.services-stage-carousel.owl-carousel .owl-dots .owl-dot span {
  display: block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #ffffff;
  position: relative;
  z-index: 1;
  border: 2px solid #e7e7e7;
}

.services-stage-carousel.owl-carousel .owl-dots .owl-dot.active span::after {
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  height: 5px;
  width: 5px;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  background: #4154f1;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}


/* ============================================================== 
     # Process
=================================================================== */
.process-items .content {
  border-left: 1px dashed #dddddd;
  padding-left: 50px;
  margin-top: 40px;
  margin-left: 30px;
}

.process-items h2 {
  font-weight: 200;
  line-height: 1.3;
  margin-top: -5px;
}

.process-items h2 strong {
  display: block;
}

.process-items .thumb {
  padding-right: 35px;
}

.process-items .content .item {
  position: relative;
  z-index: 1;
  padding-top: 10px;
  margin-bottom: 30px;
}

.process-items .content .item:last-child {
  margin-bottom: 0;
}

.process-items .content h4 {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 700;
}

.process-items .content .item p {
  margin: 0;
}

.process-items .content .item .icon {
  height: 45px;
  width: 45px;
  line-height: 45px;
  text-align: center;
  position: absolute;
  left: -74px;
  top: 0;
}

.process-items .content .item .icon i {
  transition: all 0.35s ease-in-out;
}

.process-items .content .item .icon::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: #ffffff;
  z-index: -1;
  border: 2px solid #e7e7e7;
  transform: rotate(45deg);
  border-radius: 5px;
  transition: all 0.35s ease-in-out;
}

.process-items .content .item:first-child .icon::after,
.process-items .content .item:hover .icon::after {
  background: linear-gradient(90deg, rgba(65,84,241,1) 0%, rgba(14,32,173,1) 75%);
  border: none;
}

.orange .process-items .content .item:first-child .icon::after,
.orange .process-items .content .item:hover .icon::after {
  background: linear-gradient(90deg, rgba(255,86,33,1) 0%, rgba(240,57,0,1) 77%);
  border: none;
}

.process-items .content .item:first-child .icon i,
.process-items .content .item:hover .icon i {
  color: #ffffff;
}


/* Process */

.our-process-area .process-box .single-item {
  margin-bottom: 30px;
}

.our-process-area .process-box .single-item:nth-child(2n) {
  margin-top: 50px;
}

.our-process-area .process-box {
  position: relative;
  z-index: 1;
}

.our-process-area .process-box .item:first-child .icon {
  position: relative;
}

.our-process-area .process-box .icon i {
  text-align: center;
  background: #ffffff;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
  font-size: 50px;
  color: #4154f1;
  height: 100px;
  width: 100px;
  line-height: 100px;
}

.cornflower-blue .our-process-area .process-box .icon i {
  color: #634ded;
}

.eastern-blue .our-process-area .process-box .icon i {
  color: #1baaa0;
}

.orange .our-process-area .process-box .icon i {
  color: #FF5621;
}

.our-process-area .process-box .icon i::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: url(../img/shape/shape-blue.png);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0.05;
}

.eastern-blue .our-process-area .process-box .icon i::after {
  background: url(../img/shape/shape-eastern-blue.png);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0.05;
}

.cornflower-blue .our-process-area .process-box .icon i::after {
  background: url(../img/shape/shape-blue.png);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0.05;
}

.orange .our-process-area .process-box .icon i::after {
  background: url(../img/shape/shape-orange.png);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0.05;
}

.our-process-area .process-box .item h4 {
  text-transform: capitalize;
  font-weight: 800;
  font-size: 22px;
}

.our-process-area .process-box .item p {
  margin: 0;
}

.our-process-area .process-box .item {
  padding: 60px 30px;
  background: #ffffff;
  box-shadow: 0 5px 30px 0 rgba(214, 215, 216, 0.57);
}

.our-process-area.box-less .process-box .item {
    box-shadow: inherit;
    border: none;
    padding: 0;
}

.our-process-area.box-less .process-box .single-item:nth-child(2n) {
  margin-top: 0;
}

.our-process-area.box-less .process-box .icon {
  box-shadow: inherit;
  margin-bottom: 40px;
}

.our-process-area.box-less .process-box .icon i {
  font-size: 40px;
}

.our-process-area.box-less .process-box .icon i::after {
    background: url(../img/shape/shape-2.png);
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    height: 100%;
    width: 100%;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    opacity: 0.05;
}

.our-process-area.box-less .single-item:nth-child(2) .item .icon i {
  color: rgba(27, 170, 160, 1);
}

.our-process-area.box-less .single-item:nth-child(3) .item .icon i {
  color: rgba(255, 86, 33, 1);
}

.our-process-area.box-less .single-item:nth-child(4) .item .icon i {
  color: rgba(129, 19, 137, 1);
}


/* ============================================================== 
     # Tabs
=================================================================== */

.center-tabs .nav-tabs {
  border-bottom: none;
  margin-bottom: 40px !important;
  padding-right: 20px;
}

.center-tabs .nav-tabs li {
  float: none;
  display: block;
  margin: 0;
  padding: 0;
  border: none;
  margin-bottom: 15px;
}

.center-tabs .nav-tabs li a {
  display: block;
  border: none;
  border-radius: 5px;
  padding: 10px 25px;
  position: relative;
  z-index: 1;
  background: #f7f7f7;
  font-weight: 700;
  border: 1px solid #e7e7e7;
}

.center-tabs .nav-tabs li a:hover,
.center-tabs .nav-tabs li a:focus {
  background: transparent;
}

.center-tabs .nav-tabs li a.active {
  background: linear-gradient(90deg, rgba(65,84,241,1) 0%, rgba(14,32,173,1) 75%);
  color: #ffffff;
}

.about-area .center-tabs {
  margin-top: 50px;
}

.center-tabs .tab-content {
  padding: 50px;
  background: #ffffff;
  border-bottom: 3px solid #4154f1;
  box-shadow: 0 5px 30px 0 rgba(214, 215, 216, 0.57);
}

.center-tabs .tab-content .info {
  padding-right: 35px;
  text-align: left;
}

.center-tabs .info h2,
.center-tabs .info h3 {
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 1.3;
}

.center-tabs .info h5 {
    text-transform: uppercase;
    margin-bottom: 25px;
    color: #4154f1;
    font-weight: 800;
    font-size: 18px;
}

.center-tabs .info a i {
  font-size: 14px;
  margin-right: 2px;
}

.center-tabs .info a {
  margin-top: 10px;
}

/* ============================================================== 
     # Faq
=================================================================== */

.faq-items  img {
  max-width: 120%;
}

.faq-items .faq-content {
  padding-right: 35px;
}

.faq-items .faq-content h2 {
  font-weight: 200;
  margin-bottom: 30px;
  line-height: 1.3;
  margin-top: -5px;
  text-transform: capitalize;
}

.faq-items .faq-content h2 strong {
  display: block;
  margin-top: 5px;
}

.faq-area .thumb {
  padding-left: 35px;
}

.accordion .card-header h4:after {
  font-family: "Font Awesome 5 Free";
  content: "\f06e";
  position: absolute;
  right: 30px;
  top: 30px;
  font-weight: 600;
}

.accordion .card-header h4.collapsed:after {
  content: "\f070";
  font-weight: 600;
}

.faq-area .faq-content .card {
  border: 1px solid #dddddd;
  margin-bottom: 15px;
  overflow: inherit;
  background: #ffffff;
  border-radius: 5px;
}

.faq-area .faq-content .card:last-child {
  margin-bottom: 0;
}

.faq-area .faq-content .card .card-header {
  border: none;
  background: transparent;
  padding: 0;
}

.faq-area .faq-content .card .card-header h4 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  padding: 30px;
  padding-right: 50px;
  cursor: pointer;
}

.faq-area .faq-content .card .card-body {
  padding-left: 30px;
  padding-top: 0;
}

/* Companies */
.companies-carousel .item img {
  width: auto;
  margin: auto;
}


/* ============================================================== 
     # Clients
=================================================================== */
.clients-carousel img {
  width: auto !important;
  height: 80px;
  margin: auto;
}

.clients-area.reverse .clients-carousel img {
  margin: inherit;
}

.clients-area .fixed-bg {
  opacity: 0.2;
}

.clients-area .info {
  padding-right: 35px;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}

.clients-area.bg-gray .info {
  border-right: 1px solid #cccccc;
}

.clients-area.reverse .info {
  padding-right: 15px;
  padding-left: 35px;
  border: none;
  border-left: 1px solid #e7e7e7;
}

.clients-area .info h2 {
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 0;
  margin-top: -5px;
}

.clients-area .info h2 strong {
  text-decoration: underline;
  color: #4154f1;
}

.eastern-blue .clients-area .info h2 strong {
  color: #1baaa0;
}

.orange .clients-area .info h2 strong {
  color: #FF5621;
}

.cornflower-blue .clients-area .info h2 strong {
  color: #634ded;
}

.orange .clients-area .info h2 strong {
  color: #FF5621;
}

.clients-area.text-light .info h2 strong {
  color: #ffffff;
}

/* ============================================================== 
     # Fun Fact
=================================================================== */
.fun-factor-area.bg-gray .fixed-bg {
  opacity: 0.5;
}


/* ============================================================== 
     # Our Benifits
=================================================================== */
.benefits-area .thumb {
  padding-left: 35px;
}

.benifits-area.reverse .thumb {
  padding-left: 15px;
  padding-right: 35px;
}

.benifits-area .info > h5 {
  color: #4154f1;
  font-weight: 800;
  text-transform: uppercase;
}

.eastern-blue .benifits-area .info > h5 {
  color: #1baaa0;
}

.orange .benifits-area .info > h5 {
  color: #FF5621;
}

.cornflower-blue .benifits-area .info > h5 {
  color: #634ded;
}

.benifits-area .info h2 {
  font-weight: 200;
  margin-bottom: 25px;
}

.benifits-area .info h2 strong {
  display: block;
}

.benifits-area .info ul {
  margin-top: -10px;
}

.benifits-area .info ul li {
  float: left;
  width: 50%;
  padding: 0 15px;
  position: relative;
  z-index: 1;
  padding-left: 20px;
  margin-top: 20px;
}

.benifits-area .info > a {
  margin-top: 30px;
}

.benifits-area .info ul li h5 {
  font-weight: 700;
  font-size: 16px;
  margin: 0;
}

.benifits-area .info ul li::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 10px solid #4154f1;
}

.eastern-blue .benifits-area .info ul li::after {
  border-left: 10px solid #1baaa0;
}

.orange .benifits-area .info ul li::after {
  border-left: 10px solid #FF5621;
}

.cornflower-blue .benifits-area .info ul li::after {
  border-left: 10px solid #634ded;
}


/* ============================================================== 
     # Fun Factor
=================================================================== */

.fun-factor-area {
  position: relative;
  z-index: 1;
}

.fun-factor-area .fixed-bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0.7;
}

.fun-factor-area.bg-gray .fixed-bg {
  opacity: 0.8;
}

.fun-factor-area .item .timer {
  display: block;
  font-size: 45px;
  font-weight: 800;
  line-height: 1;
  margin-top: -5px;
  margin-bottom: 10px;
  color: #232323;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, rgba(65,84,241,1) 0%, rgba(14,32,173,1) 75%);
  -webkit-background-clip: text;
}

.eastern-blue .fun-factor-area .item .timer {
  background: linear-gradient(90deg, rgba(27,170,160,1) 0%, rgba(8,115,107,1) 77%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.orange .fun-factor-area .item .timer {
  background: linear-gradient(90deg, rgba(255,86,33,1) 0%, rgba(240,57,0,1) 77%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.cornflower-blue .fun-factor-area .item .timer {
  background: linear-gradient(to bottom right, #644DED, #811389);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.fun-factor-area .item .medium {
  display: block;
  font-weight: 800;
  font-size: 16px;
  margin-bottom: -5px;
  color: #232323;
  text-transform: capitalize;
}

.fun-factor-area .fixed-bg.contain {
  opacity: 1;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

/* ============================================================== 
     # Case Studies
=================================================================== */

.case-studies-area.default-padding-top {
  margin-bottom: -50px;
}

.case-studies-area.default-padding-bottom {
  padding-bottom: 70px;
  margin: 0;
}

.case-studies-area .shape-right-bottom {
  opacity: 0.05;
}

.case-studies-area .single-item {
  margin-bottom: 30px;
}

.case-items .thumb {
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.case-items .info {
  position: relative;
  z-index: 1;
  background: #ffffff;
}

.case-items .info .cats {
    font-weight: 700;
    margin-bottom: 5px;
}

.case-items .info .cats p {
    margin: 0;
    font-weight: 700;
    color: #4154f1;
    font-size: 14px;
    position: relative;
    z-index: 1;
    padding-left: 25px;
}

.case-items .info .cats p::after {
    position: absolute;
    left: 0;
    top: 50%;
    content: "";
    height: 1px;
    width: 15px;
    background: #4154f1;
    margin-top: -1px;
}

.eastern-blue .case-items .info .cats p {
  color: #1baaa0;
}

.eastern-blue .case-items .info .cats p::after {
    background: #1baaa0;
}

.case-items .info {
    padding: 30px;
    box-shadow: 0 5px 30px 0 rgba(214, 215, 216, 0.57);
}

.case-items .info h5 {
  margin-bottom: 0;
}

.case-items .thumb .overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.case-items .thumb .overlay a {
    height: 45px;
    width: 45px;
    line-height: 45px;
    background: #ffffff;
    display: inline-block;
    border-radius: 5px;
    z-index: 9;
    color: #4154f1;
    position: absolute;
    right: 30px;
    bottom: 60px;
    opacity: 0;
    text-align: center;
}

.eastern-blue .case-items .thumb .overlay a {
    color: #1baaa0;
}

.orange .case-items .thumb .overlay a {
    color: #FF5621;
}

.cornflower-blue .case-items .thumb .overlay a {
    color: #634ded;
}

.case-items .thumb .overlay::after {
    position: absolute;
    left: 0;
    bottom: -100%;
    content: "";
    height: 100%;
    width: 100%;
    background: #000000;
    transition: all 0.35s ease-in-out;
    opacity: 0.5;
}

.case-items .item:hover .thumb .overlay::after {
  bottom: 0;
}

.case-items .item:hover .thumb .overlay a {
  opacity: 1;
  bottom: 30px;
}


/* ============================================================== 
     # Team
=================================================================== */

.team-area .single-item {
  margin-bottom: 30px;
}

.team-area .item {
  box-shadow: 0 5px 30px 0 rgba(214, 215, 216, 0.57);
  transition: all 0.35s ease-in-out;
  background: #ffffff;
}

.team-area .team-carousel .item {
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

.team-area .item:hover {
  margin-top: -15px;
}

.team-area .thumb {
  position: relative;
  z-index: 1;
}

.team-area .thumb::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: url(../img/shape/brush.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.team-area i.fas.fa-envelope {
  position: relative;
  top: 2px;
  font-weight: 500;
}

.team-area .info {
  padding: 30px;
}

.team-area .info h4 {
  font-weight: 700;
  margin: 0;
  font-size: 20px;
}

.team-area .info > span {
  display: block;
  color: #4154f1;
  margin-bottom: 10px;
}

.cornflower-blue .team-area .info > span {
  color: #634ded;
}

.eastern-blue .team-area .info > span {
  color: #1baaa0;
}

.orange .team-area .info > span {
  color: #FF5621;
}

.team-area .info .contact {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.team-area .info .contact i {
  margin-right: 5px;
  color: #1DA1F2;
  border-radius: 50%;
}

i.fas.fa-envelope-open {
  font-weight: 500;
}

.team-area .info .contact a {
  font-weight: 300;
}

.team-carousel.owl-carousel .owl-dots {
}

.team-carousel.owl-carousel .owl-dots .owl-dot {
  margin-bottom: -10px;
  margin-top: 20px;
}

.team-carousel.owl-carousel .owl-dots .owl-dot span {
  display: block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #ffffff;
  position: relative;
  z-index: 1;
  border: 2px solid #e7e7e7;
}

.team-carousel.owl-carousel .owl-dots .owl-dot.active span::after {
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  height: 5px;
  width: 5px;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  background: #4154f1;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

/* ============================================================== 
     # Pricing
=================================================================== */

.pricing-area .shape-right-bottom {
  bottom: -200px;
  opacity: 0.08;
}

.pricing-area .pricing-item {
  background: #ffffff;
  position: relative;
  z-index: 1;
  box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.1);
  padding: 50px;
}

.pricing-area .single-item {
  padding: 0;
}

.pricing-item.active {
  transform: scale(1.07);
  -webkit-transform: scale(1.07);
  -moz-transform: scale(1.07);
  -ms-transform: scale(1.07);
  -o-transform: scale(1.07);
  z-index: 9;
  border: 2px solid #4154f1;
  border-radius: 10px;
}

.orange .pricing-item.active {
  border: 2px solid #FF5621;
}

.pricing-area .pricing-item ul {
  display: inline-block;
  text-align: left;
}

.pricing-area .pricing-item li {
  margin-bottom: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.pricing-area .pricing-item li i {
  margin-left: 30px;
  color: #00a37b;
}

.pricing-area .pricing-item li i.fa-times-circle {
  color: #ff99af;
}

.pricing-area .pricing-item li:last-child {
  margin-bottom: 0;
  border: none;
  padding: 0;
  margin-top: 25px;
}

.pricing-area .pricing-item li.icon i {
  margin-left: 0;
  font-size: 60px;
  margin-top: 20px;
  margin-bottom: 30px;
  color: #232323;
  display: inline-block;
  font-size: 50px;
  height: 100px;
  width: 100px;
  line-height: 100px;
  position: relative;
  z-index: 1;
  text-align: center;
}

.pricing-area .pricing-item li.icon i::after {
  position: absolute;
  left: -8px;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: url(../img/shape/icon-shape.png);
  z-index: -1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.09;
}

.pricing-area .pricing-item.active li.icon i {
  color: #4154f1;
}

.orange .pricing-area .pricing-item.active li.icon i {
  color: #FF5621;
}

.pricing-area .pricing-item li.icon {
  text-align: center;
}

.pricing-area .pricing-item li.icon img {
  height: 120px;
  min-height: 100px;
  width: auto;
  margin-bottom: 30px;
}

.pricing-area .pricing-items {
  margin-top: 22px;
  margin-bottom: 22px;
}

.pricing-area .pricing-item li.pricing-header {
  position: relative;
  z-index: 1;
  text-align: center;
  background: border-box;
}

.pricing-area .pricing-item li.pricing-header::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 1px;
  width: 100%;
}

.pricing-area .pricing-item .price h2 {
  font-weight: 700;
  font-size: 50px;
  margin-bottom: 0;
}

.pricing-area .pricing-item .price h2 sup {
  font-size: 24px;
  top: -20px;
  font-weight: 500;
}

.pricing-area .pricing-item .price h2 sub {
  font-size: 14px;
  font-weight: 500;
}

.pricing-area .pricing-item li.pricing-header h4 {
  text-transform: capitalize;
  font-weight: 800;
  margin-bottom: 5px;
  font-size: 20px;
  color: #666666;
}

.pricing-area .pricing-item li.pricing-header::before {
  position: absolute;
  left: 0;
  bottom: 0;
}

.pricing-area .pricing-item li.pricing-header i {
  display: inline-block;
  position: relative;
  z-index: 1;
  text-align: center;
  margin-bottom: 30px;
  color: #4154f1;
  font-size: 50px;
}

.pricing-area .pricing-item li.pricing-header, 
.pricing-area .pricing-item li.price, 
.pricing-area .pricing-item li.footer,
.pricing-area .pricing-item li.icon {
  text-align: center;
  justify-content: center;
}

/* ============================================================== 
     # Subscribe
=================================================================== */

.subscribe-area .shape-right-top {
  opacity: 0.07;
  top: 120px;
}

.subscribe-area .sebscribe-items {
  padding: 80px 30px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0 5px 30px 0 rgba(214, 215, 216, 0.57);
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.subscribe-area .sebscribe-items::after {
  position: absolute;
  left: -50px;
  top: -50px;
  content: "";
  height: 150px;
  width: 150px;
  background: #4154f1;
  z-index: -1;
  transform: rotate(25deg);
  border-radius: 10px;
}

.eastern-blue .subscribe-area .sebscribe-items::after {
  background: #1baaa0;
}

.orange .subscribe-area .sebscribe-items::after {
  background: #FF5621;
}

.cornflower-blue .subscribe-area .sebscribe-items::after {
  background: #634ded;
}

.subscribe-area .sebscribe-items::before {
  position: absolute;
  left: -65px;
  top: -65px;
  content: "";
  height: 200px;
  width: 200px;
  background: #4154f1;
  z-index: -1;
  opacity: 0.5;
  transform: rotate(25deg);
  border-radius: 10px;
}

.eastern-blue .subscribe-area .sebscribe-items::before {
  background: #1baaa0;
}

.orange .subscribe-area .sebscribe-items::before {
  background: #FF5621;
}

.cornflower-blue .subscribe-area .sebscribe-items::before {
  background: #634ded;
}

.subscribe-area h2 {
  font-weight: 700;
  margin-bottom: 30px;
}

.subscribe-area h2 strong {
  display: block;
  text-transform: capitalize;
  margin-top: 5px;
  color: #4154f1;
}

.eastern-blue .subscribe-area h2 strong {
  color: #1baaa0;
}

.orange .subscribe-area h2 strong {
  color: #FF5621;
}

.cornflower-blue .subscribe-area h2 strong {
  color: #634ded;
}

.subscribe-area form {
  border: 1px solid #e7e7e7;
  border-radius: 30px;
  position: relative;
  z-index: 1;
  padding: 0 20px;
}

.seo-form form .col-lg-6 {
  padding: 0;
}

.subscribe-area form input, 
.subscribe-area form input:focus {
  border: none;
  padding: 30px;
  border-radius: 30px;
  box-shadow: inherit;
  outline: inherit;
}

.subscribe-area form button {
  top: 5px;
  position: absolute;
  right: 5px;
}

.subscribe-area form .input-group.stylish-input-group {
  border-radius: 30px;
  border: none;
}

.subscribe-area form button {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 10px 35px;
  border-radius: 30px;
  background: linear-gradient(90deg, rgba(65,84,241,1) 0%, rgba(14,32,173,1) 75%);
  color: #ffffff;
  font-weight: 700;
}

.orange .subscribe-area form button {
  background: linear-gradient(90deg, rgba(255,86,33,1) 0%, rgba(240,57,0,1) 77%);
}

.eastern-blue .subscribe-area form button  {
  background: linear-gradient(90deg, rgba(27,170,160,1) 0%, rgba(8,115,107,1) 77%);
}

.cornflower-blue .subscribe-area form button  {
  background: linear-gradient(90deg, rgba(65,84,241,1) 0%, rgba(14,32,173,1) 75%);
}

/* SEO Form */
.seo-form.subscribe-area.half-bg-gray::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 50%;
  width: 100%;
  background: #f3f7fd;
  z-index: -1;
}

.seo-form.subscribe-area form input {
  padding: 0 30px;
  border-radius: 30px;
  border-right: 1px solid #e7e7e7;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

.seo-form.subscribe-area form .form-group {
  margin: 0;
}

.seo-form.subscribe-area form .col-lg-5:last-child {
  border: none;
}

.seo-form form .col-lg-6:last-child input {
  border: none;
}

.seo-form form .col-lg-6:nth-child(2) input {
  border: none;
  border-radius: 30px;
}

.subscribe-area.seo-form form button {
  top: 0;
  right: -1px;
}


/* ============================================================== 
     # Testimonials
=================================================================== */
.testimonials-area .client-thumb {
  position: relative;
  height: 350px;
  width: 350px;
  background: #ffffff;
  margin: auto;
  border-radius: 50%;
  margin-top: 50px;
  margin-bottom: 50px;
  box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.1);
}

.testimonials-area .client-thumb::after {
  position: absolute;
  left: -15%;
  top: -15%;
  content: "";
  height: 130%;
  width: 130%;
  z-index: -1;
  border-radius: 50%;
  background: url(../img/shape/round-shape.png);
  background-size: cover;
  background-position: center;
  opacity: 0.3;
}

@keyframes rotate-animation {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.testimonials-area .client-thumb img {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background: #ffffff;
  padding: 5px;
  box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.1);
}

.testimonials-area .client-thumb img:first-child {
  position: absolute;
  left: 50%;
  top: -30px;
  margin-left: -40px;
}

.testimonials-area .client-thumb img:nth-child(2) {
  position: absolute;
  right: -10px;
  bottom: 50px;
}

.testimonials-area .client-thumb img:nth-child(3) {
  position: absolute;
  left: -10px;
  bottom: 50px;
}

.testimonials-area .client-thumb img:nth-child(4) {
  height: 200px;
  width: 200px;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  border: none;
  padding: 0;
  border-radius: 50%;
}

.testimonials-carousel .item {
  position: relative;
  z-index: 1;
  margin-top: 20px;
}

.testimonials-carousel .item::after {
  position: absolute;
  left: 0;
  top: 10px;
  content: "\f10d";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  font-size: 100px;
  z-index: -1;
  opacity: 0.07;
}

.testimonial-content h2 {
  font-weight: 700;
  margin-bottom: 25px;
}

.testimonial-content {
  margin-top: 40px;
}

.testimonials-carousel .item .author {
  display: flex;
  align-items: center;
  margin-top: 30px;
  border-top: 1px dashed #e7e7e7;
  padding-top: 30px;
}

.testimonials-carousel .item .author .thumb {
  margin-right: 20px;
}

.testimonials-carousel .item .author .thumb img {
  height: 70px;
  width: 70px;
  border-radius: 50%;
}

.testimonials-carousel .item .author .info h5 {
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 2px;
}

.testimonials-carousel .item .author .info span {
  color: #4154f1;
}

.eastern-blue .testimonials-carousel .item .author .info span {
  color: #1baaa0;
}

.testimonials-carousel.owl-carousel .owl-dots {
}

.testimonials-carousel.owl-carousel .owl-dots .owl-dot {
  margin-bottom: -10px;
  margin-top: 20px;
}

.testimonials-carousel.owl-carousel .owl-dots .owl-dot span {
  display: block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #ffffff;
  position: relative;
  z-index: 1;
  border: 2px solid #e7e7e7;
}

.testimonials-carousel.owl-carousel .owl-dots .owl-dot.active span::after {
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  height: 5px;
  width: 5px;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  background: #4154f1;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.eastern-blue .testimonials-carousel.owl-carousel .owl-dots .owl-dot.active span::after {
  background: #1baaa0;
}

/* ============================================================== 
     # Blog
=================================================================== */

.blog-area .single-item {
  margin-bottom: 30px;
}

.blog-area.full-blog .single-item:last-child {
  margin-bottom: 0;
}

.blog-area.full-blog .blog-item-box {
  margin-top: -30px;
}

.blog-area.single .blog-item-box {
  margin-top: 0;
}

.blog-area.full-blog .single-item {
  margin-bottom: 0;
  margin-top: 30px;
}

.blog-area .item .info h1,
.blog-area .item .info h2,
.blog-area .item .info h3,
.blog-area .item .info h4,
.blog-area .item .info h5,
.blog-area .item .info h6 {
  font-weight: 700;
}

.blog-area .item .info h4 {
  line-height: 1.4;
}

.blog-area .item .info h3 {
  font-weight: 700;
}

.blog-area .item .thumb img {
  border-radius: 10px;
}

.blog-area .item .thumb a {
  display: block;
  padding: 15px;
  border-radius: 10px;
}

.blog-items .item .info {
  padding: 30px;
  background: #ffffff;
  padding-top: 15px;
  border-radius: 0 0 10px 10px;
}

.blog-area.single .item .info {
  padding: 0;
  padding-top: 15px;
}

.blog-items .info h4 a:hover {
  color: #4154f1;
}

.eastern-blue .blog-items .info h4 a:hover {
  color: #1baaa0;
}

.blog-items .item {
  box-shadow: 0 5px 30px 0 rgba(214, 215, 216, 0.57);
  border-radius: 10px;
  background: #ffffff;
}

.blog-items.content-less a {
  margin-top: 10px;
}

.blog-items .meta {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.blog-items .meta .thumb {
  margin-right: 15px;
}

.blog-items .meta .thumb img {
  height: 55px;
  width: 55px;
  border-radius: 50%;
}

.blog-items .meta .content h5 {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 5px;
}

.blog-items .meta .content span {
  text-transform: capitalize;
  font-size: 14px;
  color: #4154f1;
}

.blog-items .meta ul li {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  float: left;
  margin-right: 12px;
  padding-right: 13px;
  position: relative;
  z-index: 1;
}

.blog-items .meta ul li i {
  margin-right: 3px;
  font-weight: 500;
}

.blog-items .meta ul li a {
  color: #666666;
  font-weight: 600;
}

.blog-items .meta ul li::after {
  position: absolute;
  right: 0;
  top: 7px;
  content: "";
  height: 15px;
  width: 1px;
  background: #cccccc;
}

.blog-items .meta ul li:last-child {
  margin: 0;
  padding: 0;
}

.blog-items .meta ul li:last-child::after {
  display: none;
}

.blog-items .tags {
  margin-bottom: 15px;
}

.blog-items .tags a {
  position: relative;
  z-index: 1;
  padding-right: 5px;
}

.blog-items .tags a:hover {
  color: #4154f1;
}

.eastern-blue .blog-items .tags a:hover {
  color: #1baaa0;
}

.blog-items .tags a::after {
  position: absolute;
  right: 0;
  bottom: -7px;
  content: ",";
}

.blog-items .tags a:last-child::after {
  display: none;
}

/* Pagination */

.blog-area .pagi-area .pagination {
  align-items: center;
  justify-content: center;
  margin-top: 45px;
}

.blog-area .pagi-area .pagination li a {
  display: inline-block;
  padding: 0;
  margin: 5px;
  margin-bottom: 0;
  border-radius: 50%;
  color: #4154f1;
  height: 50px;
  width: 50px;
  line-height: 50px;
}

.blog-area .pagi-area .pagination li.active a {
  background: #4154f1;
  color: #ffffff;
}

/* Sidebar */

.blog-area.left-sidebar .blog-content {
  float: right;
}

.blog-area.left-sidebar .sidebar {
  padding-right: 35px;
}

.blog-area.right-sidebar .sidebar {
  padding-left: 35px;
}

.blog-area .sidebar .title {
  display: block;
}

.blog-area .sidebar .title h4 {
  font-weight: 800;
  margin-bottom: 30px;
  margin-top: -5px;
  position: relative;
  text-transform: capitalize;
  z-index: 1;
}

.blog-area .sidebar input[type="text"] {
  border: 1px solid #e7e7e7;
  box-shadow: inherit;
  min-height: 60px;
  background: #f7f7f7;
  border-radius: 30px;
}

.blog-area .sidebar form {
  position: relative;
}

.blog-area .sidebar button[type="submit"] {
  background: transparent;
  color: #232323;
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  min-height: 50px;
  width: 50px;
  position: absolute;
  right: 5px;
  text-transform: uppercase;
  top: 5px;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  border-radius: 50%;
}

.blog-area .sidebar input[type="submit"]:hover {
  background: #4154f1 none repeat scroll 0 0;
}

.blog-area .sidebar .sidebar-item {
  float: left;
  margin-bottom: 50px;
  width: 100%;
}

.blog-area .sidebar .sidebar-item:last-child {
  margin-bottom: 0;
}

.blog-area .sidebar .sidebar-item.category .sidebar-info, 
.blog-area .sidebar .sidebar-item.archives .sidebar-info {
  margin-top: -5px;
}

.blog-area .sidebar .sidebar-item.category li {
  display: block;
  padding: 10px 0;
  position: relative;
  border-bottom: 1px solid #e7e7e7;
  margin: 3px 0;
}

.blog-area .sidebar .sidebar-item.category li a {
  display: inline-block;
  text-transform: capitalize;
  font-weight: 700;
  color: #666666;
}

.blog-area .sidebar .sidebar-item li a:hover {
  color: #4154f1;
}

.blog-area .sidebar .sidebar-item.category li a span {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 0 10px;
  position: absolute;
  right: 0;
  background: #f1f1f1;
  top: 10px;
  color: #232323;
  font-size: 14px;
}

.blog-area .sidebar .sidebar-item.category li:first-child a span {
  top: 0;
}

.blog-area .sidebar .sidebar-item.category li:first-child {
  margin-top: 0;
  padding-top: 0;
}

.blog-area .sidebar .sidebar-item.category li:first-child a::after {
  top: 0;
}

.blog-area .sidebar .sidebar-item.category li:last-child {
  border: medium none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.sidebar-item.recent-post li a {
  color: #333333;
  display: block;
  font-weight: 600;
}

.sidebar-item.recent-post li a:last-child {
  display: inline-block;
}

.sidebar-item.recent-post .meta-title {
  font-family: 'Nunito', sans-serif;
  margin-top: 15px;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 13px;
}

.sidebar-item.recent-post .meta-title i {
  margin-right: 3px;
  font-weight: 500;
  color: #4154f1;
}

.sidebar-item.recent-post li a:hover {
  color: #4154f1;
}

.color-yellow .sidebar-item.recent-post li a:hover {
  color: #ff9800;
}

.sidebar-item.recent-post li a span {
  display: inline-block;
  color: #002359;
}

.sidebar-item.recent-post li span {
  display: inline-block;
}

.sidebar-item.recent-post .meta-title a {
  color: #999999;
}

.sidebar-item.recent-post li {
  color: #cdd0d3;
}

.sidebar-item.recent-post li {
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.sidebar-item.recent-post li:last-child {
  border: medium none;
  margin: 0;
  padding: 0;
}

.sidebar-item.recent-post li:last-child {
  margin: 0;
}

.sidebar-item.recent-post li .thumb {
  display: table-cell;
  padding-top: 5px;
  vertical-align: top;
  width: 80px;
}

.sidebar-item.recent-post .thumb img {
  width: 100%;
}

.sidebar-item.recent-post li .info {
  box-shadow: inherit;
  color: #837f7e;
  display: table-cell;
  line-height: 26px;
  padding: 0;
  padding-left: 25px;
  vertical-align: top;
}

.sidebar-item.recent-post li .info a {
  color: #666666;
  text-decoration: inherit;
  font-weight: 800;
  font-size: 16px;
}

.blog-area .sidebar-item.gallery ul {
  margin: -7px;
  overflow: hidden;
}

.sidebar-item.gallery ul li {
  box-sizing: border-box;
  float: left;
  padding: 7px;
  width: 33.333%;
}

.sidebar-item.gallery ul li img {
  min-height: 70px;
  width: 100%;
}

.sidebar-item.archives ul li {
  display: block;
  margin-bottom: 20px;
  position: relative;
}

.sidebar-item.archives ul li:last-child {
  margin-bottom: 0;
}

.sidebar-item.archives ul li a {
  display: inline-block;
  font-weight: 800;
  text-transform: capitalize;
  z-index: 1;
  padding-left: 25px;
  font-size: 14px;
  color: #666666;
}

.sidebar-item.archives ul li a::after {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  content: "\f07b";
  font-family: "Font Awesome 5 Free";
  height: 8px;
  left: 0;
  position: absolute;
  width: 8px;
  font-weight: 500;
}

.sidebar-item.social-sidebar ul {
  margin-bottom: -10px;
  overflow: hidden;
}

.sidebar-item.social-sidebar li {
  display: inline-block;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}

.sidebar-item.social-sidebar li a {
  background: #002359 none repeat scroll 0 0;
  border: medium none;
  color: #ffffff;
  display: inline-block;
  height: 50px;
  line-height: 54px;
  margin-bottom: 5px;
  text-align: center;
  width: 50px;
  border-radius: 10px;
  font-size: 14px;
}

.sidebar-item.social-sidebar li a:hover {
  color: #ffffff !important;
}

.sidebar-item.social-sidebar li.facebook a {
  background: #3B5998 none repeat scroll 0 0;
}

.sidebar-item.social-sidebar li.twitter a {
  background: #1DA1F2 none repeat scroll 0 0;
}

.sidebar-item.social-sidebar li.pinterest a {
  background: #BD081C none repeat scroll 0 0;
}

.sidebar-item.social-sidebar li.g-plus a {
  background: #DB4437 none repeat scroll 0 0;
}

.sidebar-item.social-sidebar li.linkedin a {
  background: #0077B5 none repeat scroll 0 0;
}

.sidebar-item.tags ul {
  margin-top: -8px;
}

.sidebar-item.tags ul li {
  display: inline-block;
}

.sidebar-item.tags ul li a {
  border: 1px solid #e7e7e7;
  display: inline-block;
  font-weight: 800;
  margin-top: 8px;
  margin-right: 5px;
  padding: 5px 25px;
  text-transform: capitalize;
  font-size: 13px;
  border-radius: 30px;
  color: #666666;
  background: #fafafa;
}

.sidebar-item.tags ul li a:hover {
  color: #4154f1;
}


/* Blog Single */
.blog-area.single .item .content-box span {
  background: #4154f1 none repeat scroll 0 0;
  color: #ffffff;
  display: inline-block;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 3px 20px;
  text-transform: uppercase;
}

.blog-area.single .item .content-box h2,
.blog-area.single .item .content-box h3,
.blog-area.single .item .content-box h4 {
  font-weight: 500;
}

.blog-area.single .content-box .cats {
  float: left;
  margin-right: 5px;
}

.blog-area.single .content-box .meta .date {
  float: right;
  font-family: 'Nunito', sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.blog-area.single .content-box .meta {
  overflow: hidden;
  width: 100%;
  border: none;
  margin: 0;
  padding: 0;
}

.blog-area .blog-items .info > ul li {
  margin-bottom: 15px;
  color: #232323;
  position: relative;
  z-index: 1;
  padding-left: 18px;
}

.blog-area .blog-items .info > ul {
  margin-bottom: 25px;
  margin-top: 25px;
}

.blog-area .blog-items .info > ul li::after {
  position: absolute;
  left: 0;
  top: 11px;
  content: "";
  background: #666666;
  height: 7px;
  width: 7px;
  border-radius: 50%;
}

.blog-area.single .content-box .cats a:last-child::after {
  display: none;
}

.blog-area.single .item {
  margin-bottom: 0;
  box-shadow: inherit;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 50px;
  border-radius: inherit;
}

.blog-area.single .item .thumb a {
  padding: 0;
  padding-bottom: 15px;
}

.blog-area .item blockquote {
    position: relative;
    z-index: 1;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-left: 30px;
    background: #fafafa;
    padding: 50px;
    font-weight: 500;
    border-left: 4px solid #4154f1;
}

.blog-area .blog-content .post-tags, 
.blog-area .blog-content .share {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.blog-area .blog-content .share li {
  display: inline-block;
  margin-left: 15px;
}

.blog-area .blog-content .share li a {
  display: inline-block;
  color: #4154f1;
}

.blog-area .blog-content .share li.facebook a {
  color: #3B5998;
}

.blog-area .blog-content .share li.twitter a {
  color: #1DA1F2;
}

.blog-area .blog-content .share li.pinterest a {
  color: #BD081C;
}

.blog-area .blog-content .share li.g-plus a {
  color: #DB4437;
}

.blog-area .blog-content .share li.linkedin a {
  color: #0077B5;
}

.blog-area .blog-content .post-tags .tags a {
  background: #f7f7f7;
  padding: 10px 15px;
  font-size: 14px;
  margin-right: 3px;
  margin-bottom: 5px;
  display: inline-block;
}

.blog-area.single .post-pagi-area {
  margin-top: 15px;
  overflow: hidden;
}

.blog-area.single .post-pagi-area a {
  display: inline-block;
  font-weight: 800;
  text-transform: capitalize;
  color: #4154f1;
  padding: 8px 30px;
  border: 2px solid #e7e7e7;
  border-radius: 5px;
}

.blog-area.single .post-pagi-area a:hover {
  color: #4154f1;
}

.blog-area.single.color-yellow .post-pagi-area a:hover {
  color: #ff9800;
}

.blog-area.single .post-pagi-area a:last-child {
  float: right;
}

.blog-area.single .post-pagi-area a:first-child i {
  margin-right: 3px;
}

.blog-area.single .post-pagi-area a:last-child i {
  margin-left: 3px;
}

.comments-area .commen-item .comments-info p {
  display: block;
  margin: 0;
}

.blog-area .contact-comments .submit {
  margin-bottom: 0;
}

.responsive-video {
  position: relative;
  padding-bottom: 56.25%;
 /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.responsive-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Comments */

.blog-area .comments-area .comments-list {
  padding: 30px;
  background: #f7f7f7;
  margin-top: 30px;
}

.blog-area .comments-form {
  margin-top: 50px;
}

.comments-list .commen-item .avatar {
  float: left;
  height: 105px;
  width: 105px;
}

.comments-list .commen-item .content {
  display: table-cell;
  vertical-align: top;
}

.comments-list .commen-item .avatar img {
  height: 80px;
  width: 80px;
}

.comments-list .commen-item {
  margin-bottom: 25px;
}

.comments-list .commen-item:last-child {
  margin-bottom: 0;
}

.comments-list .commen-item.reply {
  padding-left: 80px;
}

.comments-area {
  margin-top: 30px;
}

.comments-area .comments-title h2,
.comments-area .comments-title h3,
.comments-area .comments-title h4 {
  font-weight: 700;
  margin-bottom: 30px;
  text-transform: capitalize;
}

.comments-list .commen-item .content h3, 
.comments-list .commen-item .content h4, 
.comments-list .commen-item .content h5 {
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 0;
}

.comments-list .commen-item .content .title {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-weight: 700;
}

.comments-list .commen-item .content .title span {
  border-left: 1px solid #e7e7e7;
  padding-left: 15px;
  margin-left: 15px;
}

.comments-info a {
  border: 1px solid #e7e7e7;
  color: #002359;
  display: inline-block;
  font-size: 12px;
  margin-top: 5px;
  padding: 1px 10px;
  text-transform: uppercase;
  font-weight: 600;
}

.comments-info a:hover {
  color: #4154f1;
}

.color-yellow .comments-info a:hover {
  color: #ff9800;
}

.comments-info a i {
  margin-right: 10px;
}

.comments-form input, 
.comments-form textarea {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: #e7e7e7;
  border-image: none;
  border-radius: inherit;
  border-style: none none solid;
  border-width: medium medium 1px;
  box-shadow: inherit;
}

.comments-form textarea {
  min-height: 180px;
  padding: 15px;
}

.blog-area.full-blog.single-blog .form-group.submit {
  margin-bottom: 0;
}

.comments-form button {
  background: linear-gradient(90deg, rgba(65,84,241,1) 0%, rgba(14,32,173,1) 75%);
  border: 1px solid transparent;
  color: #ffffff;
  display: inline-block;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 25px;
  margin-top: 20px;
  padding: 15px 45px;
  text-transform: uppercase;
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  border-radius: 30px;
}

.comments-form button:hover {
  color: #ffffff;
  background-color: #4154f1;
  border: 1px solid transparent;
}

.comments-area .commen-item .comments-info {
  margin-bottom: 15px;
}

.comments-form .title h2, 
.comments-form .title h3, 
.comments-form .title h4 {
  border-bottom: 1px solid #e7e7e7;
  font-weight: 700;
  margin-bottom: 30px;
  padding-bottom: 25px;
  text-transform: capitalize;
}

.blog-area .contact-comments .comments {
  margin-top: 20px;
}

.blog-area.single .blog-items .item .contact-comments .col-md-6 {
  float: left;
  padding: 0 15px;
}


/* ============================================================== 
     # Contact
=================================================================== */
.contact-us-area form input {
  border: none;
  background: #f7f7f7;
  padding: 0 20px;
}

.contact-us-area form textarea {
  border: none;
  background: #f7f7f7;
  padding: 20px;
  min-height: 180px;
}

.contact-us-area form button {
  background: linear-gradient(90deg, rgba(65,84,241,1) 0%, rgba(14,32,173,1) 75%);
  padding: 12px 35px;
  border-radius: 30px;
  color: #ffffff;
  font-weight: 700;
  margin-top: 15px;
}

.cornflower-blue .contact-us-area form button {
  background: linear-gradient(to bottom right, #644DED, #811389);
}

.eastern-blue .contact-us-area form button {
  background: linear-gradient(90deg, rgba(27,170,160,1) 0%, rgba(8,115,107,1) 77%);
}

.orange .contact-us-area form button {
  background: linear-gradient(90deg, rgba(255,86,33,1) 0%, rgba(240,57,0,1) 77%);
}

form img.loader {
  margin-left: 5px;
}

.contact-us-area form button i {
  margin-left: 3px;
}

.contact-us-area .contact-form h2 {
  font-weight: 200;
  margin-bottom: 30px;
}

.contact-us-area .contact-form h2 strong {
  display: block;
  margin-top: 5px;
}

.contact-us-area .address-items {
  padding: 60px 30px;
  background: linear-gradient(to bottom right, #4154F1, #061169);
  margin-right: 15px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.cornflower-blue .contact-us-area .address-items {
  background: linear-gradient(to bottom right, #644DED, #811389);
}

.eastern-blue .contact-us-area .address-items {
  background: linear-gradient(90deg, rgba(27,170,160,1) 0%, rgba(8,115,107,1) 77%);
}

.orange .contact-us-area .address-items {
  background: linear-gradient(90deg, rgba(255,86,33,1) 0%, rgba(240,57,0,1) 77%);
}

.contact-us-area .address-items::after {
  position: absolute;
  right: -50px;
  top: -50px;
  content: "";
  height: 150px;
  width: 150px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
}

.contact-us-area .address-items::before {
  position: absolute;
  right: -50px;
  top: -50px;
  content: "";
  height: 180px;
  width: 180px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
}

.contact-us-area .address-items .item {
  margin-bottom: 30px;
  padding-bottom: 19px;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
}

.contact-us-area .address-items .item:last-child {
  margin: 0;
  padding: 0;
  border: none;
}

.contact-us-area .address-items .item h4 {
  font-weight: 700;
}

.contact-us-area .address-items .item li {
  font-weight: 800;
}

.contact-us-area .address-items .item li span {
  font-weight: 400;
}

/* Google Maps */

.google-maps iframe {
  border: medium none;
  height: 550px;
  margin-bottom: -10px;
  width: 100%;
}

.maps-area {
  overflow: hidden;
}


/* ============================================================== 
     # Login 
=================================================================== */
.login-area, 
.login-area div {
  height: 100%;
}

.login-area div.login, 
.login-area div.login div {
  height: auto;
}

.login-area .login-box {
    padding-top: 50px;
    padding-bottom: 50px;
  }

.login-area .login-box {
  display: table;
  width: 100%;
}

.login-area .login-box .login {
  display: table-cell;
  height: auto;
  vertical-align: middle;
}

.login-area .content {
  background: #ffffff none repeat scroll 0 0;
  border-radius: 5px;
  box-shadow: 0 0 10px #cccccc;
  padding: 30px;
  text-align: center;
}

.login-area .content img {
  margin-bottom: 30px;
}

.login-area .content input {
  border: none;
  box-shadow: inherit;
  padding-left: 25px;
  border-bottom: 1px solid #e7e7e7;
  border-radius: inherit;
}

.login-area .form-group {
  position: relative;
}

.login-area .form-group i {
  position: absolute;
  left: 0;
  top: 16px;
  color: #999999;
}

.login-area .content button {
  background: linear-gradient(90deg, rgba(65,84,241,1) 0%, rgba(14,32,173,1) 75%);
  border: medium none;
  border-radius: 5px;
  box-shadow: inherit;
  color: #ffffff;
  font-weight: 800;
  padding: 8px;
  text-transform: uppercase;
  width: 100%;
}

.login-area .content .sign-up {
  display: inline-block;
  margin-top: 30px;
}

.login-area .content .sign-up a {
  color: #4154f1;
  margin-left: 5px;
}

.login-area .link {
  text-align: right;
  margin-bottom: 25px;
  text-decoration: underline;
}

.login-area .social-login li {
  display: inline-block;
  margin: 0 3px;
}

.login-area .social-login li a {
  background: #002359 none repeat scroll 0 0;
  border: medium none;
  color: #ffffff;
  display: inline-block;
  height: 40px;
  line-height: 44px;
  text-align: center;
  width: 40px;
  border-radius: 50%;
  font-size: 12px;
}

.login-area .social-login li.facebook a {
  background: #3B5998 none repeat scroll 0 0;
}

.login-area .social-login li.twitter a {
  background: #1DA1F2 none repeat scroll 0 0;
}

.login-area .social-login li.pinterest a {
  background: #BD081C none repeat scroll 0 0;
}

.login-area .social-login li.g-plus a {
  background: #DB4437 none repeat scroll 0 0;
}

.login-area .social-login li.linkedin a {
  background: #0077B5 none repeat scroll 0 0;
}

.login-area .social-login {
  border-top: 1px dashed #dddddd;
  margin-top: 15px;
  padding-top: 30px;
}

.login-area .social-login h5 {
  font-weight: 900;
  margin-bottom: 15px;
  color: #666666;
  font-size: 16px;
  text-transform: uppercase;
}

/* ============================================================== 
     # Error Page
=================================================================== */
.error-page-area .thumb {
  padding-right: 35px;
}

.error-page-area h2 {
  font-weight: 700;
  margin-bottom: 25px;
}

.error-page-area a {
  margin-top: 10px;
}


/* ============================================================== 
     # Footer
=================================================================== */


footer .widget-title,
footer img {
  margin-bottom: 30px;
  font-weight: 800;
}

footer .widget-title {
  display: inline-block;
  position: relative;
  z-index: 1;
}

footer .f-item.recent-post a {
  font-size: 17px;
  font-weight: 600;
  line-height: 32px;
  color: #96aac1;
}

footer .f-item.recent-post span a {
  font-size: 13px;
  font-weight: 800;
  color: #ffffff;
}

footer .f-item.recent-post a:hover {
  color: #ffffff;
}

footer .recent-post span {
  display: block;
  margin-top: 15px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
}

footer .recent-post span i {
  margin-right: 4px;
}

footer .f-item.about {
  margin-right: 30px;
}

footer .recent-post .item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  margin-bottom: 30px;
  padding-bottom: 30px;
}

footer .recent-post .item:last-child {
  margin: 0;
  padding: 0;
  border: none;
}

footer .address h5 {
  margin-bottom: 10px;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 18px;
}

footer .address span {
  color: #96aac1;
}

footer .address span i {
  margin-right: 5px;
  font-size: 20px;
  position: relative;
  top: 2px;
  color: #ffffff;
}

footer .address li {
  display: flex;
  margin-top: 25px;
}

footer .address li i {
  font-size: 30px;
  min-width: 50px;
  display: inline-block;
}

footer .address {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  ;
  padding-top: 15px;
}

footer .link li {
  margin-bottom: 15px;
}

footer .link li:last-child {
  margin-bottom: 0;
}

footer .link li a {
  color: #96aac1;
}

footer .link a:hover {
  color: #ffffff !important;
}

footer .footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  padding: 25px 0;
}

footer .footer-bottom li {
  display: inline-block;
}

footer .footer-bottom p {
  margin: 0;
}

footer .footer-bottom .text-right li {
  margin-left: 19px;
}


/* ============================================================== 
     # Preloader 
=================================================================== */
.site-preloader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: white;
}

.site-preloader svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.site-preloader .loader-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
}

.site-preloader .ring {
  width: 100px;
  height: 100px;
  border: 3px solid rgba(2, 109, 255, 0.2);
  border-top-color: #4154f1;
  border-radius: 50%;
  text-align: center;
  line-height: 150px;
  font-size: 2rem;
  color: white;
  letter-spacing: 3px;
  text-transform: uppercase;
  text-shadow: 0 0 10px white;
  -webkit-animation: rotation 1s infinite linear;
          animation: rotation 1s infinite linear;
  margin: 0 auto;
}

.eastern-blue .site-preloader .ring {
  border: 3px solid rgba(27, 170, 160, 0.2);
  border-top-color: #1baaa0;
  color: #ffffff;
}

.orange .site-preloader .ring {
  border: 3px solid rgba(255, 86, 33, 0.2);
  border-top-color: #FF5621;
  color: #ffffff;
}

.cornflower-blue .site-preloader .ring {
  border: 3px solid rgba(99, 77, 237, 0.2);
  border-top-color: #634ded;
  color: #ffffff;
}

.site-preloader h2 {
  color: #4154f1;
  text-align: center;
  text-transform: capitalize;
  text-shadow: 0 0 10px white;
  font-weight: 700;
  margin: 30px auto 0;
  display: block;
}

.eastern-blue .site-preloader h2 {
  color: #1baaa0;
}

.orange .site-preloader h2 {
  color: #FF5621;
}

.cornflower-blue .site-preloader h2 {
  color: #634ded;
}

.site-preloader h2 {
  color: #4154f1;
  text-align: center;
  text-transform: capitalize;
  text-shadow: 0 0 10px white;
  font-weight: 700;
  margin: 30px auto 0;
  display: block;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


/* ============================================================== 
     # Demo 
=================================================================== */

.demo-area.demo-conten.default-padding {
  padding-bottom: 30px;
}

.demo-area .single-item {
  position: relative;
  z-index: 1;
}

.demo-area .item {
    margin-bottom: 50px
}

.demo-area .single-item .info {
  padding: 15px 10px;
  text-align: center;
}
.demo-area .single-item .info h4 {
    font-size: 16px;
    font-weight: 800;
    margin: 0;
    text-transform: uppercase;
    margin-top: 15px;
}
.banner-area .demo-banner h1 {
  font-weight: 700;
  margin-bottom: 20px;
}
.banner-area .demo-banner img {
  height: auto;
  margin-bottom: 30px;
  max-height: 80px;
}
.demo-area .def-heading h2 {
  font-weight: 900;
  margin: 25px 0;
  text-transform: uppercase;
  color: #333333;
}
.demo-footer {
  padding-bottom: 80px;
}

.introduction-area h2 span {
  color: #fd0060;
}

.introduction-area h4 {
  font-weight: 300;
  line-height: 30px;
}

.introduction-area h2 {
  color: #ffffff;
  display: inline-block;
  font-weight: 700;
  padding-bottom: 20px;
  position: relative;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.introduction-area h2::before {
  background: #fd0060 none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 2px;
  left: 50%;
  margin-left: -10px;
  position: absolute;
  width: 50px;
}

.introduction-area h2::after {
  background: #ffffff none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 2px;
  left: 50%;
  margin-left: -40px;
  position: absolute;
  width: 25px;
}

footer.demo .copyright {
  margin-top: 15px;
}
.demo-area .comming-soon.item img {
    opacity: 0.3;
}
.banner-area.demo-banner {
  padding: 50px 0;
}
.demo-area .site-heading {
  margin-bottom: 30px;
}
.demo-area img {
  background: #ffffff none repeat scroll 0 0;
  padding: 10px;
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

.demo-area.demo-conten .single-item a {
    position: relative;
    display: block;
}

.demo-area.demo-conten .single-item a span {
    position: absolute;
    right: 15px;
    top: -14px;
    background: linear-gradient(90deg, rgba(30,85,189,1) 0%, rgba(67,126,235,1) 100%);
    color: #ffffff;
    padding: 1px 35px;
    border-radius: 30px;
    box-shadow: 0 0 10px #cccccc;
}